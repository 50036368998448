import { Box, Flex, Text } from '@chakra-ui/react';
import { useCallback, useContext, useState } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';
import { FormCoupon, Icon, Input } from 'components';
import { UserContext } from 'state';
import { Coupon } from 'types';
import { RndApi } from 'services';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { useLocalisation } from 'hooks';
import styled from '@emotion/styled';

export const CouponInput = () => {
  const { t } = useLocalisation();

  const { couponName, setCouponAmount, setCouponName, setCouponType } =
    useContext(UserContext);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const fetchCoupon = async (data: FieldValues) => {
    const couponName = data.coupon;

    try {
      setLoading(true);

      const coupon = await RndApi.fetchCoupon<Coupon>(couponName);

      setShowErrorMessage(false);
      setCouponName(couponName);
      setCouponAmount(coupon.amount);
      setCouponType(coupon.type);
    } catch {
      setShowErrorMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const renderForm = useCallback(
    (control: Control, errors: FieldErrors) => {
      return (
        <>
          <StyledInput>
            <Input
              type="text"
              name="coupon"
              defaultValue={couponName}
              label={t.emailFunnel.discount}
              control={control}
              error={errors.coupon?.message as string}
            />
          </StyledInput>
        </>
      );
    },
    [couponName, showErrorMessage],
  );

  return (
    <FormCoupon
      mode="onSubmit"
      isLoading={isLoading}
      submitButtonText="Apply"
      validationSchema={yup.object().shape({
        coupon: yup.string(),
      })}
      onSubmit={fetchCoupon}
      render={renderForm}
    >
      {showErrorMessage && (
        <Flex alignItems="center" gap="0.5rem" mt="0.5rem" ml="0.5rem">
          <Box>
            <Icon src="CrossRed" fill="#FF4F4F" />
          </Box>
          <Text color="red.400" fontWeight="600">
            {t.emailFunnel.error}
          </Text>
        </Flex>
      )}
    </FormCoupon>
  );
};

const StyledInput = styled(Box)`
  .chakra-input {
    color: ${({ theme }) => theme.colors.neutral[900]};
    background-color: ${({ theme }) => theme.colors.neutral[0]};
    border: 0.0625rem solid;
    border-color: ${({ theme }) => theme.colors.neutral[775]};
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 400;

    &::placeholder {
      color: ${({ theme }) => theme.colors.purple[600]};
      opacity: 0.5;
    }

    &:focus,
    &:hover {
      border: 0.0625rem solid;
      border-color: ${({ theme }) => theme.colors.purple[500]};
      box-shadow: none;
    }
  }
`;
