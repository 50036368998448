export const TEXTS = {
  common: {
    submitButtonText: {
      en: 'Continue',
      es: 'Continuar',
      de: 'Weiter',
      fr: 'Continuer',
    },
    errorMessage: {
      en: 'Something went wrong',
      es: 'Algo salió mal',
      de: 'Etwas ist schiefgegangen',
      fr: "Une erreur s'est produite",
    },
    unlimited: {
      en: 'Unlimited',
      es: 'Ilimitado',
      de: 'Unbegrenzt',
      fr: 'Illimité',
    },
    perPhoto: {
      en: 'per photo',
      es: 'por foto',
      de: 'pro Foto',
      fr: 'par photo',
    },
    discount: {
      en: 'Discount',
      es: 'Descuento',
      de: 'Rabatt',
      fr: 'Remise',
    },
    coupon: {
      en: 'Coupon',
      es: 'Cupón',
      de: 'Gutschein',
      fr: 'Coupon',
    },
    couponName: {
      en: 'NY2025',
      es: 'NY2025',
      de: 'NY2025',
      fr: 'NY2025',
    },
    sale: {
      en: 'Sale',
      es: 'Oferta',
      de: 'Angebot',
      fr: 'Promo',
    },
    save: {
      en: '🔥SAVE',
      es: '🔥AHORRA',
      de: '🔥SPAREN',
      fr: '🔥ÉCONOMISEZ',
    },
    mostPopular: {
      en: 'Most popular',
      es: 'Más popular',
      de: 'Am beliebtesten',
      fr: 'Le plus populaire',
    },
  },
  products: {
    '3-month': {
      name: {
        en: '3-month Memowrite plan + 1 free hardcover book',
        es: 'Plan Memowrite de 3 meses + 1 libro de tapa dura gratis',
        de: '3-monatiger Memowrite-Plan + 1 kostenloses Hardcover-Buch',
        fr: 'Plan Memowrite de 3 mois + 1 livre relié gratuit',
      },
      description: {
        en: `You can cancel anytime at no cost, or the plan ends automatically when your book is complete. While active, a $PRICE charge applies every 3 months.`,
        es: 'Puede cancelar en cualquier momento sin costo alguno, o el plan termina automáticamente cuando su libro está completo. Mientras esté activo, se aplica un cargo de $PRICE cada 3 meses.',
        de: 'Sie können jederzeit kostenlos kündigen, oder der Plan endet automatisch, wenn Ihr Buch fertiggestellt ist. Solange der Plan aktiv ist, fällt alle 3 Monate eine Gebühr von $PRICE an.',
        fr: "Vous pouvez annuler à tout moment sans frais, ou le plan se termine automatiquement lorsque votre livre est terminé. Tant qu'il est actif, des frais de $PRICE s'appliquent tous les 3 mois.",
      },
    },
    '6-month': {
      name: {
        en: '6-month Memowrite plan + 1 free hardcover book',
        es: 'Plan Memowrite de 6 meses + 1 libro de tapa dura gratis',
        de: '6-monatiger Memowrite-Plan + 1 kostenloses Hardcover-Buch',
        fr: 'Plan Memowrite de 6 mois + 1 livre relié gratuit',
      },
      description: {
        en: 'You can cancel anytime at no cost, or the plan ends automatically when your book is complete. While active, a $PRICE charge applies every 6 months.',
        es: 'Puede cancelar en cualquier momento sin costo alguno, o el plan termina automáticamente cuando su libro está completo. Mientras esté activo, se aplica un cargo de $PRICE cada 6 meses.',
        de: 'Sie können jederzeit kostenlos kündigen, oder der Plan endet automatisch, wenn Ihr Buch fertiggestellt ist. Solange der Plan aktiv ist, fällt alle 6 Monate eine Gebühr von $PRICE an.',
        fr: "Vous pouvez annuler à tout moment sans frais, ou le plan se termine automatiquement lorsque votre livre est terminé. Tant qu'il est actif, des frais de $PRICE s'appliquent tous les 6 mois.",
      },
    },
    '12-month': {
      name: {
        en: '12-month Memowrite plan + 1 free hardcover book',
        es: 'Plan Memowrite de 12 meses + 1 libro de tapa dura gratis',
        de: '12-monatiger Memowrite-Plan + 1 kostenloses Hardcover-Buch',
        fr: 'Plan Memowrite de 12 mois + 1 livre relié gratuit',
      },
      description: {
        en: 'You can cancel anytime at no cost, or the plan ends automatically when your book is complete. While active, a $PRICE charge applies every 12 months.',
        es: 'Puede cancelar en cualquier momento sin costo alguno, o el plan termina automáticamente cuando su libro está completo. Mientras esté activo, se aplica un cargo de $PRICE cada 12 meses.',
        de: 'Sie können jederzeit kostenlos kündigen, oder der Plan endet automatisch, wenn Ihr Buch fertiggestellt ist. Solange der Plan aktiv ist, fällt alle 12 Monate eine Gebühr von $PRICE an.',
        fr: "Vous pouvez annuler à tout moment sans frais, ou le plan se termine automatiquement lorsque votre livre est terminé. Tant qu'il est actif, des frais de $PRICE s'appliquent tous les 12 mois.",
      },
    },
    'gift-card': {
      name: {
        en: 'Memowrite Gift Card',
        es: 'Tarjeta de regalo Memowrite',
        de: 'Memowrite-Geschenkkarte',
        fr: 'Carte cadeau Memowrite',
      },
      description: {
        en: '12-month access to Memowrite services',
        es: 'Acceso de 12 meses a los servicios de Memowrite',
        de: '12-monatiger Zugang zu den Memowrite-Diensten',
        fr: 'Accès de 12 mois aux services Memowrite',
      },
    },
    'upsell-additional-books-1x': {
      name: {
        en: '1 more copy',
        es: '1 copia más',
        de: '1 Kopie mehr',
        fr: '1 exemplaire de plus',
      },
      description: {
        en: 'For a couple special people',
        es: 'Para un par de personas especiales',
        de: 'Für zwei besondere Menschen',
        fr: 'Pour quelques personnes spéciales',
      },
    },
    'upsell-additional-books-2x': {
      name: {
        en: '2 more copies',
        es: '2 copias más',
        de: '2 Kopien mehr',
        fr: '2 exemplaires de plus',
      },
      description: {
        en: 'For a couple special people',
        es: 'Para un par de personas especiales',
        de: 'Für zwei besondere Menschen',
        fr: 'Pour quelques personnes spéciales',
      },
    },
    'upsell-additional-books-4x': {
      name: {
        en: '4 more copies',
        es: '4 copias más',
        de: '4 Kopien mehr',
        fr: '4 exemplaires de plus',
      },
      description: {
        en: 'For bigger families',
        es: 'Para familias más grandes',
        de: 'Für größere Familien',
        fr: 'Pour les familles nombreuses',
      },
    },
    'upsell-additional-books-6x': {
      name: {
        en: '6 more copies',
        es: '6 copias más',
        de: '6 Kopien mehr',
        fr: '6 exemplaires de plus',
      },
      description: {
        en: 'For bigger families',
        es: 'Para familias más grandes',
        de: 'Für größere Familien',
        fr: 'Pour les familles nombreuses',
      },
    },
    'upsell-additional-books-8x': {
      name: {
        en: '8 more copies',
        es: '8 copias más',
        de: '8 Kopien mehr',
        fr: '8 exemplaires de plus',
      },
      description: {
        en: 'For bigger families and friends',
        es: 'Para familias grandes y amigos',
        de: 'Für größere Familien und Freunde',
        fr: 'Pour les grandes familles et les amis',
      },
    },
    'upsell-additional-books-10x': {
      name: {
        en: '10 more copies',
        es: '10 copias más',
        de: '10 Kopien mehr',
        fr: '10 exemplaires de plus',
      },
      description: {
        en: 'For bigger families and friends',
        es: 'Para familias grandes y amigos',
        de: 'Für größere Familien und Freunde',
        fr: 'Pour les grandes familles et les amis',
      },
    },
  },
  footer: {
    text: {
      en: 'All rights reserved.',
      es: 'Todos los derechos reservados.',
      de: 'Alle Rechte vorbehalten.',
      fr: 'Tous droits réservés.',
    },
  },
  timer: {
    text1: {
      en: 'Your offer expires soon:',
      es: 'Su oferta expira pronto:',
      de: 'Ihr Angebot läuft bald ab:',
      fr: 'Votre offre expire bientôt :',
    },
    text2: {
      en: 'Limited time special offer',
      es: 'Oferta especial por tiempo limitado',
      de: 'Sonderangebot – nur für kurze Zeit',
      fr: 'Offre spéciale pour une durée limitée',
    },
    text2_1: {
      en: '',
      es: '',
      de: 'min',
      fr: '',
    },
    text3: {
      en: 'This offer won’t be available again:',
      es: 'Esta oferta no estará disponible nuevamente:',
      de: 'Dieses Angebot wird nicht wieder verfügbar sein:',
      fr: 'Cette offre se termine dans :',
    },
  },
  timerXmas: {
    text1: {
      en: '🔥 New Year Sale:',
      es: '🔥 Oferta de Año Nuevo:',
      de: '🔥 Neujahrsverkauf:',
      fr: '🔥 Vente du Nouvel An :',
    },
    text2: {
      en: 'Hurry up! Limited time offer!',
      es: '¡Apúrate! ¡Oferta por tiempo limitado!',
      de: 'Beeil dich! Angebot nur für kurze Zeit!',
      fr: 'Dépêchez-vous ! Offre limitée dans le temps !',
    },
  },
  builderFunnel: {
    steps: {
      email: {
        en: 'Email',
        es: 'Código de descuento',
        de: 'Rabattcode',
        fr: 'Code de réduction',
      },
      plan: {
        en: 'Plan',
        es: 'Código de descuento',
        de: 'Rabattcode',
        fr: 'Code de réduction',
      },
      type: {
        en: 'Book type',
        es: 'Código de descuento',
        de: 'Rabattcode',
        fr: 'Code de réduction',
      },
      design: {
        en: 'Book design',
        es: 'Código de descuento',
        de: 'Rabattcode',
        fr: 'Code de réduction',
      },
      checkout: {
        en: 'Checkout',
        es: 'Código de descuento',
        de: 'Rabattcode',
        fr: 'Code de réduction',
      },
    },
    emailText1: {
      en: `You're eligible to redeem the coupon code <b>NY2025</b> on the next step and <b>save up to 64%</b> on your order.`,
      es: `Eres elegible para canjear el código de cupón <b>NY2025</b> en el siguiente paso y <b>ahorrar hasta un 64%</b> en tu pedido.`,
      de: `Sie sind berechtigt, den Gutscheincode <b>NY2025</b> im nächsten Schritt einzulösen und <b>bis zu 64 %</b> bei Ihrer Bestellung zu sparen.`,
      fr: `Vous êtes éligible pour utiliser le code promo <b>NY2025</b> à l’étape suivante et <b>économiser jusqu’à 64 %</b> sur votre commande.`,
    },
    emailText2: {
      en: 'Don’t miss this special offer!',
      es: '¡No te pierdas esta oferta especial!',
      de: 'Verpassen Sie dieses Sonderangebot nicht!',
      fr: 'Ne manquez pas cette offre spéciale !',
    },
    whatIncluded: {
      en: 'What’s included',
      es: 'Qué incluye',
      de: 'Was ist enthalten',
      fr: 'Ce qui est inclus',
    },
    subtotal: {
      en: 'Subtotal',
      es: 'Subtotal',
      de: 'Zwischensumme',
      fr: 'Sous-total',
    },
    memoir: {
      en: 'Personal Memoir',
      es: 'Memorias personales',
      de: 'Persönliche Memoiren',
      fr: 'Mémoires personnelles',
    },
    copy: {
      en: '1 copy',
      es: '1 copia',
      de: '1 Kopie',
      fr: '1 exemplaire',
    },
    summaryItems: {
      one: {
        en: 'Answer 50+ tailored questions to guide your story',
        es: 'Responde a más de 50 preguntas personalizadas para guiar tu historia',
        de: 'Beantworten Sie über 50 maßgeschneiderte Fragen, um Ihre Geschichte zu führen',
        fr: 'Répondez à plus de 50 questions personnalisées pour guider votre histoire',
      },
      two: {
        en: 'Our team will refine the grammar and craft a memoir you’ll be proud of',
        es: 'Nuestro equipo mejorará la gramática y creará unas memorias de las que estarás orgulloso',
        de: 'Unser Team verfeinert die Grammatik und erstellt Memoiren, auf die Sie stolz sein können',
        fr: 'Notre équipe affinera la grammaire et rédigera des mémoires dont vous serez fier',
      },
      three: {
        en: 'A premium, full-color A5 book (5.83" x 8.27").',
        es: 'Un libro A5 premium a todo color (5.83" x 8.27").',
        de: 'Ein hochwertiges, farbiges A5-Buch (5,83" x 8,27").',
        fr: 'Un livre A5 de qualité supérieure en couleurs (5,83" x 8,27").',
      },
      four: {
        en: 'Up to 500 pages with unlimited revisions to make sure it’s perfect',
        es: 'Hasta 500 páginas con revisiones ilimitadas para asegurarte de que sea perfecto',
        de: 'Bis zu 500 Seiten mit unbegrenzten Überarbeitungen, um sicherzustellen, dass es perfekt ist',
        fr: 'Jusqu’à 500 pages avec des révisions illimitées pour garantir sa perfection',
      },
      five: {
        en: 'Customizable cover options to match your theme (20 choices)',
        es: 'Opciones de portada personalizables para adaptarse a tu tema (20 opciones)',
        de: 'Anpassbare Cover-Optionen, die zu Ihrem Thema passen (20 Auswahlmöglichkeiten)',
        fr: 'Options de couverture personnalisables pour correspondre à votre thème (20 choix)',
      },
    },
    guarantee: {
      en: 'Money back guarantee',
      es: 'Garantía de devolución de dinero',
      de: 'Geld-zurück-Garantie',
      fr: 'Garantie de remboursement',
    },
    shipping: {
      en: 'Free shipping',
      es: 'Envío gratis',
      de: 'Kostenloser Versand',
      fr: 'Livraison gratuite',
    },
    excellent: {
      en: 'Excellent',
      es: 'Excelente',
      de: 'Exzellent',
      fr: 'Excellent',
    },
    reviews: {
      en: 'Based on <b>2 142 reviews</b>',
      es: 'Basado en <b>2 142 reseñas</b>',
      de: 'Basierend auf <b>2 142 Bewertungen</b>',
      fr: 'Basé sur <b>2 142 avis</b>',
    },
    story: {
      en: 'A Story I Never Thought I’d Write',
      es: 'Una historia que nunca pensé escribir',
      de: 'Eine Geschichte, von der ich nie dachte, dass ich sie schreiben würde',
      fr: 'Une histoire que je n’aurais jamais pensé écrire',
    },
    comment: {
      en: 'I never dreamed I’d write a book, but now my story is safe for my family. Thank you, Memowrite!',
      es: 'Nunca soñé con escribir un libro, pero ahora mi historia está segura para mi familia. ¡Gracias, Memowrite!',
      de: 'Ich hätte nie gedacht, ein Buch zu schreiben, aber jetzt ist meine Geschichte sicher für meine Familie. Danke, Memowrite!',
      fr: 'Je n’aurais jamais rêvé d’écrire un livre, mais maintenant mon histoire est en sécurité pour ma famille. Merci, Memowrite !',
    },
    date: {
      en: '2 May',
      es: '2 de mayo',
      de: '2. Mai',
      fr: '2 mai',
    },
    choosePlan: {
      en: 'Choose your plan',
      es: 'Elige tu plan',
      de: 'Wählen Sie Ihren Plan',
      fr: 'Choisissez votre plan',
    },
    planMessage: {
      en: '<b>Each plan includes 50 questions</b> to guide you through sharing your memories, with pacing that fits your schedule. You can add extra copies of your book after purchase.',
      es: '<b>Cada plan incluye 50 preguntas</b> para guiarte a compartir tus recuerdos, con un ritmo que se adapta a tu horario. Puedes añadir copias adicionales de tu libro después de la compra.',
      de: '<b>Jeder Plan enthält 50 Fragen</b>, die Ihnen helfen, Ihre Erinnerungen zu teilen, mit einem Tempo, das zu Ihrem Zeitplan passt. Sie können nach dem Kauf zusätzliche Exemplare Ihres Buches hinzufügen.',
      fr: '<b>Chaque plan comprend 50 questions</b> pour vous guider dans le partage de vos souvenirs, à un rythme adapté à votre emploi du temps. Vous pouvez ajouter des copies supplémentaires de votre livre après l’achat.',
    },
    couponText: {
      en: `The coupon code <b>NY2025</b> has been applied. <b>Your discount of up to 64 is now active.</b> Enjoy your savings!`,
      es: `El código de cupón <b>NY2025</b> ha sido aplicado. <b>Tu descuento de hasta un 64 ahora está activo.</b> ¡Disfruta tus ahorros!`,
      de: `Der Gutscheincode <b>NY2025</b> wurde angewendet. <b>Ihr Rabatt von bis zu 64 ist jetzt aktiv.</b> Genießen Sie Ihre Ersparnisse!`,
      fr: `Le code promo <b>NY2025</b> a été appliqué. <b>Votre réduction jusqu’à 64 est maintenant active.</b> Profitez de vos économies !`,
    },
    bottomText: {
      en: '<b>1 free hardcover book</b> included',
      es: '<b>1 libro de tapa dura gratis</b> incluido',
      de: '<b>1 kostenloses Hardcover-Buch</b> enthalten',
      fr: '<b>1 livre relié gratuit</b> inclus',
    },
    designTitle: {
      en: 'Stylish cover design',
      es: 'Diseño de portada elegante',
      de: 'Stilvolles Cover-Design',
      fr: 'Design de couverture élégant',
    },
    bookType: {
      title: {
        en: 'Select book type',
        es: 'Selecciona el tipo de libro',
        de: 'Buchtyp auswählen',
        fr: 'Choisir le type de livre',
      },
      subtitle: {
        en: 'Your story deserves a cover as special as the words inside. Choose a <b>Paperback cover</b> for a lightweight, portable option that’s easy to enjoy anywhere. Or, select a <b>Hardcover design</b> that’s made to stand the test of time.',
        es: 'Tu historia merece una portada tan especial como las palabras en su interior. Elige una <b>portada de tapa blanda</b> para una opción ligera y portátil que sea fácil de disfrutar en cualquier lugar. O selecciona un <b>diseño de tapa dura</b> que esté hecho para durar.',
        de: 'Ihre Geschichte verdient eine so besondere Hülle wie die Worte darin. Wählen Sie eine <b>Softcover</b> für eine leichte, tragbare Option, die überall genossen werden kann. Oder entscheiden Sie sich für ein <b>Hardcover-Design</b>, das die Zeit überdauert.',
        fr: 'Votre histoire mérite une couverture aussi spéciale que les mots qu’elle contient. Choisissez une <b>couverture souple</b> pour une option légère et portable, facile à apprécier partout. Ou, optez pour une <b>couverture rigide</b>, conçue pour résister à l’épreuve du temps.',
      },
      titleSimple: {
        en: 'Paperback',
        es: 'Tapa blanda',
        de: 'Taschenbuch',
        fr: 'Couverture souple',
      },
      subtitleSimple: {
        en: 'Create your memoir with a practical and affordable paperback edition.',
        es: 'Crea tus memorias con una edición de tapa blanda práctica y asequible.',
        de: 'Erstellen Sie Ihre Memoiren mit einer praktischen und erschwinglichen Taschenbuchausgabe.',
        fr: 'Créez vos mémoires avec une édition pratique et abordable en couverture souple.',
      },
      titleStylish: {
        en: 'Hardcover',
        es: 'Tapa dura',
        de: 'Hardcover',
        fr: 'Couverture rigide',
      },
      subtitleStylish: {
        en: 'Create your memoir in a hardcover, built to last and be passed down through generations.',
        es: 'Crea tus memorias en una tapa dura, diseñada para durar y ser transmitida a través de generaciones.',
        de: 'Erstellen Sie Ihre Memoiren in einem Hardcover, das für die Ewigkeit gebaut ist und von Generation zu Generation weitergegeben werden kann.',
        fr: 'Créez vos mémoires dans une couverture rigide, conçue pour durer et être transmise de génération en génération.',
      },
    },
    bookDesign: {
      title: {
        en: 'Select cover design',
        es: 'Selecciona el diseño de portada',
        de: 'Cover-Design auswählen',
        fr: 'Choisissez le design de couverture',
      },
      subtitle: {
        en: 'Your book’s cover should reflect the special story within. Choose a <b>Simple Cover</b> for a classic look that speaks volumes. Or, select <b>Stylish Cover</b> to include cherished photos, creating a design as personal and vibrant as your memories.',
        es: '¡Tu libro merece una portada tan única como tu historia! Elige una <b>Portada básica</b> para un aspecto limpio y clásico, o selecciona una <b>Portada elegante</b> para mostrar tus fotos favoritas y un diseño profesional.',
        de: 'Ihr Buch verdient ein Cover, das so einzigartig ist wie Ihre Geschichte! Wählen Sie ein <b>einfaches Cover</b> für ein sauberes, klassisches Aussehen oder ein <b>stilvolles Cover</b>, um Ihre Lieblingsfotos und ein professionell gestaltetes Design zu präsentieren.',
        fr: 'Votre livre mérite une couverture aussi unique que votre histoire ! Choisissez une <b>Couverture simple</b> pour un look propre et classique, ou optez pour une <b>Couverture élégante</b> pour mettre en valeur vos photos préférées et un design professionnel.',
      },
      titleSimple: {
        en: 'Simple cover design',
        es: 'Diseño de portada simple',
        de: 'Einfaches Cover-Design',
        fr: 'Design de couverture simple',
      },
      subtitleSimple: {
        en: 'Includes: Book title and colour design.',
        es: 'Incluye: Título del libro y diseño de color.',
        de: 'Beinhaltet: Buchtitel und Farbschema.',
        fr: 'Comprend : Titre du livre et conception en couleur.',
      },
      titleStylish: {
        en: 'Stylish cover with photos',
        es: 'Portada elegante con fotos',
        de: 'Stilvolles Cover mit Fotos',
        fr: 'Couverture élégante avec photos',
      },
      subtitleStylish: {
        en: 'Includes: Covers templates library, photos, book title and colour design.',
        es: 'Incluye: Biblioteca de plantillas de portadas, fotos, título del libro y diseño de color.',
        de: 'Beinhaltet: Cover-Vorlagenbibliothek, Fotos, Buchtitel und Farbschema.',
        fr: 'Comprend : Bibliothèque de modèles de couvertures, photos, titre du livre et conception en couleur.',
      },
    },
    products: {
      '3-month-builder': {
        title: {
          en: 'Write a book in 3-months',
          es: 'Escribe un libro en 3 meses',
          de: 'Schreiben Sie ein Buch in 3 Monaten',
          fr: 'Écrivez un livre en 3 mois',
        },
        subtitle: {
          en: 'Fast and focused',
          es: 'Rápido y enfocado',
          de: 'Schnell und fokussiert',
          fr: 'Rapide et concentré',
        },
        item1: {
          en: '<b>3-month access</b> to Memowrite services',
          es: '<b>3 meses de acceso</b> a los servicios de Memowrite',
          de: '<b>3-monatiger Zugriff</b> auf Memowrite-Dienste',
          fr: '<b>3 mois d’accès</b> aux services Memowrite',
        },
        item2: {
          en: 'Around <b>4 questions per week</b> (or answer faster to get the book sooner)',
          es: 'Aproximadamente <b>4 preguntas por semana</b> (o responde más rápido para obtener el libro antes)',
          de: 'Etwa <b>4 Fragen pro Woche</b> (oder schneller antworten, um das Buch früher zu erhalten)',
          fr: 'Environ <b>4 questions par semaine</b> (ou répondez plus vite pour obtenir le livre plus tôt)',
        },
        item3: {
          en: 'Perfect for those who <b>want the book fast</b>',
          es: 'Perfecto para aquellos que <b>quieren el libro rápido</b>',
          de: 'Perfekt für diejenigen, die <b>das Buch schnell wollen</b>',
          fr: 'Parfait pour ceux qui <b>veulent le livre rapidement</b>',
        },
        item4: {
          en: 'Need to commit more <b>time and focus right away</b>',
          es: 'Necesitan comprometer más <b>tiempo y enfoque de inmediato</b>',
          de: 'Erfordert mehr <b>Zeit und Konzentration sofort</b>',
          fr: 'Nécessite de consacrer plus de <b>temps et d’attention immédiatement</b>',
        },
      },
      '6-month-builder': {
        title: {
          en: 'Write a book in 6-months',
          es: 'Escribe un libro en 6 meses',
          de: 'Schreiben Sie ein Buch in 6 Monaten',
          fr: 'Écrivez un livre en 6 mois',
        },
        subtitle: {
          en: 'STEADY AND BALANCED',
          es: 'ESTABLE Y EQUILIBRADO',
          de: 'AUSGEGLICHEN UND KONTINUIERLICH',
          fr: 'ÉQUILIBRÉ ET CONSTANT',
        },
        item1: {
          en: '<b>6-month access</b> to Memowrite services',
          es: '<b>6 meses de acceso</b> a los servicios de Memowrite',
          de: '<b>6-monatiger Zugriff</b> auf Memowrite-Dienste',
          fr: '<b>6 mois d’accès</b> aux services Memowrite',
        },
        item2: {
          en: `Around <b>2 questions per week</b> (answer at your pace, get the book as soon as it's finished)`,
          es: `Aproximadamente <b>2 preguntas por semana</b> (responde a tu ritmo, obtén el libro tan pronto como esté terminado)`,
          de: `Ungefähr <b>2 Fragen pro Woche</b> (beantworten Sie in Ihrem Tempo, erhalten Sie das Buch, sobald es fertig ist)`,
          fr: `Environ <b>2 questions par semaine</b> (répondez à votre rythme, obtenez le livre dès qu’il est terminé)`,
        },
        item3: {
          en: 'Great for those seeking a <b>balanced pace for answering and reflecting</b>',
          es: 'Ideal para quienes buscan un <b>ritmo equilibrado para responder y reflexionar</b>',
          de: 'Ideal für diejenigen, die ein <b>ausgeglichenes Tempo für das Beantworten und Reflektieren</b> suchen',
          fr: 'Idéal pour ceux qui recherchent un <b>rythme équilibré pour répondre et réfléchir</b>',
        },
        item4: {
          en: 'A steady writing <b>experience with no rush</b>',
          es: 'Una experiencia de escritura <b>constante sin prisas</b>',
          de: 'Ein kontinuierliches Schreiberlebnis <b>ohne Eile</b>',
          fr: 'Une expérience d’écriture <b>constante et sans précipitation</b>',
        },
      },
      '12-month-builder': {
        title: {
          en: 'Write a book in 12-months',
          es: 'Escribe un libro en 12 meses',
          de: 'Schreiben Sie ein Buch in 12 Monaten',
          fr: 'Écrivez un livre en 12 mois',
        },
        subtitle: {
          en: 'RELAXED AND COMPREHENSIVE',
          es: 'RELAXADO Y COMPLETO',
          de: 'ENTSPANNT UND UMFASSEND',
          fr: 'DÉTENDU ET COMPLET',
        },
        item1: {
          en: '<b>12-month access</b> to Memowrite services',
          es: '<b>12 meses de acceso</b> a los servicios de Memowrite',
          de: '<b>12-monatiger Zugriff</b> auf Memowrite-Dienste',
          fr: '<b>12 mois d’accès</b> aux services Memowrite',
        },
        item2: {
          en: `Around <b>1 question per week</b> (answer at your pace, get the book as soon as it's finished)`,
          es: `Aproximadamente <b>1 pregunta por semana</b> (responde a tu ritmo, obtén el libro tan pronto como esté terminado)`,
          de: `Ungefähr <b>1 Frage pro Woche</b> (beantworten Sie in Ihrem Tempo, erhalten Sie das Buch, sobald es fertig ist)`,
          fr: `Environ <b>1 question par semaine</b> (répondez à votre rythme, obtenez le livre dès qu’il est terminé)`,
        },
        item3: {
          en: 'Ideal for those who prefer <b>thoughtful, in-depth reflection</b>',
          es: 'Ideal para quienes prefieren una <b>reflexión profunda y meditada</b>',
          de: 'Ideal für diejenigen, die eine <b>nachdenkliche, tiefgründige Reflexion</b> bevorzugen',
          fr: 'Idéal pour ceux qui préfèrent une <b>réflexion approfondie et réfléchie</b>',
        },
        item4: {
          en: '<b>Relaxed experience</b> with time to dive deep into your memories',
          es: 'Una <b>experiencia relajada</b> con tiempo para profundizar en tus recuerdos',
          de: 'Eine <b>entspannte Erfahrung</b> mit Zeit, um tief in Ihre Erinnerungen einzutauchen',
          fr: 'Une <b>expérience détendue</b> avec du temps pour plonger profondément dans vos souvenirs',
        },
      },
    },
  },
  emailFunnel: {
    discount: {
      en: 'Discount code',
      es: 'Código de descuento',
      de: 'Rabattcode',
      fr: 'Code de réduction',
    },
    success: {
      en: 'Coupon applied successfully',
      es: 'Cupón aplicado con éxito',
      de: 'Gutschein erfolgreich angewendet',
      fr: 'Coupon appliqué avec succès',
    },
    error: {
      en: 'Could not apply coupon',
      es: 'No se pudo aplicar el cupón',
      de: 'Gutschein konnte nicht angewendet werden',
      fr: "Impossible d'appliquer le coupon",
    },
  },
  timerBlackFriday: {
    text1: {
      en: '🔥 New Year Sale:',
      es: '🔥 Oferta de Año Nuevo:',
      de: '🔥 Neujahrsverkauf:',
      fr: '🔥 Vente du Nouvel An :',
    },
    text2: {
      en: 'Hurry up! Limited time offer!',
      es: '¡Apúrate! ¡Oferta por tiempo limitado!',
      de: 'Beeil dich! Angebot nur für kurze Zeit!',
      fr: 'Dépêchez-vous ! Offre limitée dans le temps !',
    },
    text3: {
      en: '🔥 New Year Sale:',
      es: '🔥 Oferta de Año Nuevo:',
      de: '🔥 Neujahrsverkauf:',
      fr: '🔥 Vente du Nouvel An :',
    },
    text4: {
      en: 'Hurry up! Limited time offer!',
      es: '¡Apúrate! ¡Oferta por tiempo limitado!',
      de: 'Beeil dich! Angebot nur für kurze Zeit!',
      fr: 'Dépêchez-vous ! Offre limitée dans le temps !',
    },
  },
  discountXmas: {
    text1: {
      en: `Yay! You have code <b>NY2025</b> applied.`,
      es: `¡Yay! Has aplicado el código <b>NY2025</b>.`,
      de: `Juhu! Du hast den Code <b>NY2025</b> angewendet.`,
      fr: `Youpi ! Vous avez appliqué le code <b>NY2025</b>.`,
    },
    text1_1: {
      en: `Yay! You have code <b>NY2025 & $COUPON</b> applied.`,
      es: `¡Yay! Has aplicado el código <b>NY2025 & $COUPON</b>.`,
      de: `Juhu! Du hast den Code <b>NY2025 & $COUPON</b> angewendet.`,
      fr: `Youpi ! Vous avez appliqué le code <b>NY2025 & $COUPON</b>.`,
    },
    text2: {
      en: 'This discount code saving you',
      es: 'Este código de descuento te ahorra',
      de: 'Dieser Rabattcode spart dir',
      fr: 'Ce code de réduction vous fait économiser',
    },
    text2_1: {
      en: 'These discount codes saving you',
      es: 'Estos códigos de descuento te ahorran',
      de: 'Diese Rabattcodes sparen Ihnen',
      fr: 'Ces codes de réduction vous font économiser',
    },
  },
  discountBlackFriday: {
    text1: {
      en: 'Yay! You have code BF applied.',
      es: '¡Genial! Tienes el código BF aplicado.',
      de: 'Hurra! Der Code BF wurde angewendet.',
      fr: 'Bravo ! Le code BF est appliqué.',
    },
    text2: {
      en: 'This discount code saving you',
      es: 'Este código de descuento te ahorra',
      de: 'Dieser Rabattcode spart Ihnen',
      fr: 'Ce code de réduction vous fait économiser',
    },
  },
  breadcrumbs: {
    text1: {
      en: 'Information',
      es: 'Información',
      de: 'Informationen',
      fr: 'Informations',
    },
    text2: {
      en: 'Payment',
      es: 'Pago',
      de: 'Zahlung',
      fr: 'Paiement',
    },
    text3: {
      en: 'Receipt',
      es: 'Recibo',
      de: 'Beleg',
      fr: 'Reçu',
    },
  },
  emailPage: {
    text1: {
      en: 'Enter your email address',
      es: 'Ingrese su dirección de correo electrónico',
      de: 'Geben Sie Ihre E-Mail-Adresse ein',
      fr: 'Entrez votre adresse e-mail',
    },
    text2: {
      en: 'Please provide your email to receive important information and updates about your book. We never send spam or promotional emails.',
      es: 'Por favor, ingrese su correo para recibir información importante y actualizaciones sobre su libro. Nunca enviamos spam ni correos promocionales.',
      de: 'Bitte geben Sie Ihre E-Mail-Adresse an, um wichtige Informationen und Aktualisierungen zu Ihrem Buch zu erhalten. Wir senden niemals Spam oder Werbe-E-Mails.',
      fr: "Veuillez fournir votre adresse e-mail pour recevoir des informations importantes et des mises à jour sur votre livre. Nous n'envoyons jamais de spam ou d'e-mails promotionnels.",
    },
    textGift: {
      en: '🎁 After purchase, you’ll receive a printable gift card that you can either print out or send directly to someone special.',
      es: '🎁 Después de la compra, recibirás una tarjeta de regalo imprimible que podrás imprimir o enviar directamente a alguien especial.',
      de: '🎁 Nach dem Kauf erhalten Sie eine druckbare Geschenkkarte, die Sie entweder ausdrucken oder direkt an jemanden Besonderen senden können.',
      fr: "🎁 Après votre achat, vous recevrez une carte cadeau imprimable que vous pourrez imprimer ou envoyer directement à quelqu'un de spécial.",
    },
  },
  emailForm: {
    label: {
      en: 'Email address',
      es: 'Correo electrónico',
      de: 'E-Mail-Adresse',
      fr: 'Adresse e-mail',
    },
    message1: {
      en: 'The email must be a valid email address',
      es: 'El correo debe ser una dirección válida',
      de: 'Die E-Mail muss eine gültige Adresse sein',
      fr: "L'email doit être une adresse valide",
    },
    message2: {
      en: 'Please enter a valid email address.',
      es: 'Por favor, ingrese una dirección de correo válida.',
      de: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      fr: 'Veuillez entrer une adresse e-mail valide.',
    },
  },
  paymentsPage: {
    text1: {
      en: 'Edit',
      es: 'Editar',
      de: 'Bearbeiten',
      fr: 'Modifier',
    },
    text2: {
      en: 'Select payment method',
      es: 'Seleccione método de pago',
      de: 'Zahlungsmethode auswählen',
      fr: 'Sélectionnez un mode de paiement',
    },
    text2_1: {
      en: 'Select a secure payment method',
      es: 'Seleccione un método de pago seguro',
      de: 'Wählen Sie eine sichere Zahlungsmethode aus',
      fr: 'Sélectionnez un mode de paiement sécurisé',
    },
    text3: {
      en: 'Credit card',
      es: 'Tarjeta de crédito',
      de: 'Kreditkarte',
      fr: 'Carte de crédit',
    },
    creditCard: {
      label1: {
        en: 'Full name',
        es: 'Nombre completo',
        de: 'Vollständiger Name',
        fr: 'Nom complet',
      },
      placeholder1: {
        en: 'John Smith',
        es: 'Juan Pérez',
        de: 'Max Mustermann',
        fr: 'Jean Dupont',
      },
      label2: {
        en: 'Card number',
        es: 'Número de tarjeta',
        de: 'Kartennummer',
        fr: 'Numéro de carte',
      },
      submitButtonText: {
        en: 'Make secure payment',
        es: 'Realizar pago seguro',
        de: 'Sichere Zahlung vornehmen',
        fr: 'Effectuer un paiement sécurisé',
      },
    },
  },
  orderSummary: {
    text1: {
      en: 'Order summary',
      es: 'Resumen de pedido',
      de: 'Bestellübersicht',
      fr: 'Résumé de la commande',
    },
    text2: {
      en: 'Free & Fast shipping',
      es: 'Envío gratis y rápido',
      de: 'Kostenloser & schneller Versand',
      fr: 'Livraison gratuite et rapide',
    },
    text3: {
      en: 'Limited time offer',
      es: 'Oferta por tiempo limitado',
      de: 'Angebot für begrenzte Zeit',
      fr: 'Offre limitée dans le temps',
    },
    text4: {
      en: 'FREE',
      es: 'GRATIS',
      de: 'KOSTENLOS',
      fr: 'GRATUIT',
    },
    text5: {
      en: 'Shipping',
      es: 'Envío',
      de: 'Versand',
      fr: 'Expédition',
    },
    text6: {
      en: 'Free',
      es: 'Gratis',
      de: 'Kostenlos',
      fr: 'Gratuit',
    },
    text7: {
      en: 'Total:',
      es: 'Total:',
      de: 'Gesamt:',
      fr: 'Total:',
    },
    textGift1: {
      en: 'Memowrite Gift Card',
      es: 'Tarjeta de Regalo Memowrite',
      de: 'Memowrite-Geschenkkarte',
      fr: 'Carte Cadeau Memowrite',
    },
    textGift2: {
      en: '12-month access to Memowrite services',
      es: 'Acceso a los servicios de Memowrite por 12 meses',
      de: '12 Monate Zugang zu Memowrite-Diensten',
      fr: 'Accès aux services Memowrite pendant 12 mois',
    },
  },
  progress: {
    text1: {
      en: 'ORDER SUBMITTED',
      es: 'PEDIDO REALIZADO',
      de: 'BESTELLUNG ÜBERMITTELT',
      fr: 'COMMANDE EFFECTUÉE',
    },
    text2: {
      en: 'SPECIAL OFFER',
      es: 'OFERTA ESPECIAL',
      de: 'SONDERANGEBOT',
      fr: 'OFFRE SPÉCIALE',
    },
    text3: {
      en: 'ORDER RECEIPT',
      es: 'RECIBO DEL PEDIDO',
      de: 'BESTELLBELEG',
      fr: 'RÉCAPITULATIF DE COMMANDE',
    },
  },
  upsellAdditionalBooks: {
    text1: {
      en: 'Want a few more copies to share with family and friends—for just',
      es: '¿Quiere algunas copias extra para compartir con familiares y amigos—por solo',
      de: 'Möchten Sie außerdem ein paar Exemplare für Familie und Freunde für nur',
      fr: 'Vous voulez quelques exemplaires supplémentaires à partager avec votre famille et vos amis, pour seulement',
    },
    text1_1: {
      en: '',
      es: '',
      de: ' bestellen',
      fr: '',
    },
    text2: {
      en: 'per book',
      es: 'por libro',
      de: 'pro Buch',
      fr: 'par livre ',
    },
    text3: {
      en: 'You got <b>one copy</b> with your order. Most customers buy extra to share with loved ones. You can always get more copies at',
      es: 'Recibió <b>una copia</b> con su pedido. La mayoría de los clientes compra copias adicionales para compartir con seres queridos. Siempre puede obtener más copias en',
      de: 'Mit Ihrer Bestellung erhalten Sie bereits <b>ein Exemplar</b>. Trotzdem entscheiden sich die meisten unserer Kunden für zusätzliche Exemplare, um diese mit ihren Liebsten zu teilen. Entscheiden Sie sich jederzeit für weitere Exemplare zum Preis von',
      fr: 'Vous avez reçu <b>un exemplaire</b> avec votre commande. La plupart des clients achètent des exemplaires supplémentaires pour partager avec leurs proches. Vous pouvez toujours obtenir d’autres exemplaires à',
    },
    text3_1: {
      en: 'each, <b>but order now to save up to</b>',
      es: 'cada una, <b>pero pide ahora para ahorrar hasta</b>',
      de: 'oder <b>bestellen Sie jetzt und sparen Sie bis zu</b>',
      fr: "chacun, <b>mais commandez maintenant pour économiser jusqu'à</b>",
    },
    text3_2: {
      en: '<b>per book</b>',
      es: '<b>por libro</b>',
      de: '<b>pro Buch</b>',
      fr: '<b>par livre</b>.',
    },
    text4: {
      en: 'How many copies would you like?',
      es: '¿Cuántas copias le gustaría?',
      de: 'Wie viele Exemplare dürfen es sein?',
      fr: "Combien d'exemplaires souhaitez-vous ?",
    },
    text5: {
      en: 'Sale',
      es: 'Oferta',
      de: 'Angebot',
      fr: 'Promo',
    },
    text6: {
      en: 'SAVE',
      es: 'AHORRE',
      de: 'SPAREN',
      fr: 'ÉCONOMISEZ',
    },
    text7: {
      en: 'Yes, get my extra copies now!',
      es: '¡Sí, quiero mis copias adicionales ahora!',
      de: 'Ja, ich möchte weitere Exemplare bestellen!',
      fr: 'Oui, je veux mes exemplaires supplémentaires maintenant !',
    },
    text8: {
      en: 'No thanks, I’m good.',
      es: 'No gracias, estoy bien.',
      de: 'Nein danke, kein Interesse.',
      fr: 'Non merci, ça ira.',
    },
    testimonials: {
      text1: {
        en: '“I got four more copies for my kids, so they all have a piece of our family history. They were so touched. The discount made it even better.”',
        es: '“Compré cuatro copias adicionales para mis hijos, así todos tienen una parte de nuestra historia familiar. Estaban muy emocionados. El descuento lo hizo aún mejor.”',
        de: '„Ich habe vier weitere Exemplare für meine Kinder gekauft, damit sie alle ein Stück unserer Familiengeschichte bekommen. Sie waren sehr gerührt. Der Rabatt war da natürlich sehr willkommen."',
        fr: "« J'ai acheté quatre exemplaires supplémentaires pour mes enfants, pour qu'ils aient tous un morceau de notre histoire familiale. Ils étaient très émus. La remise a rendu cela encore mieux. »",
      },
      text2: {
        en: '“I ordered extra for my grandkids. They loved reading about our family memories. Glad I got the deal when I did!”',
        es: '“Encargué copias adicionales para mis nietos. Les encantó leer sobre los recuerdos de nuestra familia. ¡Qué bueno que aproveché la oferta!”',
        de: '„Ich habe zusätzliche Exemplare für meine Enkel bestellt. Für sie war es natürlich total spannend, unsere Familienerinnerungen aus einer anderen Zeit zu lesen. Ich bin froh, dass ich das Angebot genutzt habe!"',
        fr: "« J'ai commandé des exemplaires supplémentaires pour mes petits-enfants. Ils ont adoré lire sur les souvenirs de notre famille. Heureusement que j'ai profité de l'offre ! »",
      },
      text3: {
        en: '“Bought copies for my daughters—perfect for sharing our family story. The savings were a nice bonus too.”',
        es: '“Compré copias para mis hijas—perfectas para compartir nuestra historia familiar. El ahorro fue un buen extra también.”',
        de: '„Ich habe gleich noch zwei Exemplare für meine Töchter gekauft – perfekt, um unsere Familiengeschichte zu teilen. Das Angot wurde dankend angenommen."',
        fr: "« J'ai acheté des exemplaires pour mes filles—parfait pour partager l'histoire de notre famille. Les économies étaient un joli bonus aussi. »",
      },
    },
  },
  upsellPhotoEnhancer: {
    text1: {
      en: 'Enhance Your Memoir with Stunning, Restored Photos',
      es: 'Mejora tu memoria con fotos impresionantes y restauradas',
      de: 'Verleihen Sie Ihren Memoiren mit beeindruckend restaurierten Fotos noch mehr Leben.',
      fr: 'Sublimez vos mémoires avec des photos restaurées et époustouflantes',
    },
    text2: {
      en: 'Bring your stories to life by including beautifully enhanced photos. Our Photo Enhancer service upgrades your images, restoring and refining them to make sure every photo looks its best in your memoir.',
      es: 'Da vida a tus historias incluyendo fotos bellamente mejoradas. Nuestro servicio de Mejora de Fotos transforma tus imágenes, restaurándolas y perfeccionándolas para que cada foto luzca espectacular en tu memoria.',
      de: 'Erwecken Sie Ihre Geschichten zum Leben mit wunderschön aufbereitete Fotos. Unser Foto-Enhancer-Dienst verbessert, restauriert und verfeinert Ihre Bilder, damit jedes Foto in Ihren Memoiren perfekt zur Geltung kommt.',
      fr: "Notre service d'amélioration de photos Photo Enhancer améliore vos images, les restaure et les affine pour que chaque photo soit parfaite dans vos mémoires.",
    },
    text3: {
      en: 'Why Upgrade to Photo Enhancer?',
      es: '¿Por qué mejorar con Photo Enhancer?',
      de: 'Warum auf Photo Enhancer upgraden?',
      fr: 'Pourquoi choisir le service Photo Enhancer ?',
    },
    items: {
      text1: {
        en: `<b>Restore Faded Memories:</b> Over time, photos can fade, discolor, or become damaged. Our Photo Enhancer will digitally restore your images, so they look as vibrant as they did the day they were taken.`,
        es: `<b>Restaura recuerdos desvanecidos:</b> Con el tiempo, las fotos pueden desteñirse, decolorarse o dañarse. Nuestro servicio de mejora de fotos restaurará digitalmente tus imágenes, devolviéndoles la vitalidad que tenían el día en que fueron tomadas.`,
        de: `<b>Verblasste Erinnerungen wiederherstellen:</b> Im Laufe der Zeit können Fotos verblassen, sich verfärben oder beschädigt werden. Unser Foto-Enhancer stellt Ihre Bilder digital wieder her, damit sie so lebendig aussehen wie am Tag ihrer Aufnahme.`,
        fr: `<b>Restaurez des souvenirs fanés :</b> Avec le temps, les photos peuvent s'effacer, se décolorer ou être endommagées. Notre Photo Enhancer restaurera numériquement vos images pour qu'elles retrouvent leur éclat d'origine.`,
      },
      text2: {
        en: `<b>High-Quality Resolution:</b> Low-resolution or blurry images? No problem! Our tool sharpens and improves the quality of your photos, making sure they appear crisp and clear in your hardcover book.`,
        es: `<b>Resolución de alta calidad:</b> ¿Imágenes de baja resolución o borrosas? ¡No hay problema! Nuestra herramienta agudiza y mejora la calidad de tus fotos, asegurando que se vean nítidas y claras en tu libro de tapa dura.`,
        de: `<b>Hochwertige Auflösung:</b> Niedrige Auflösung oder verschwommene Bilder? Kein Problem! Unser Tool schärft und verbessert die Qualität Ihrer Fotos, sodass sie in Ihrem Hardcover-Buch gestochen scharf erscheinen.`,
        fr: `<b>Résolution de haute qualité :</b> Des images floues ou en basse résolution ? Pas de problème ! Notre outil affine et améliore la qualité de vos photos pour qu'elles apparaissent nettes et claires dans votre livre relié.`,
      },
      text3: {
        en: `<b>Color Correction and Balance:</b> Old photos can lose their original colors or develop a yellow tint. The Photo Enhancer will automatically adjust colors, ensuring they look natural and balanced.`,
        es: `<b>Corrección y equilibrio de color:</b> Las fotos antiguas pueden perder sus colores originales o desarrollar un tono amarillento. La mejora de fotos ajustará automáticamente los colores, asegurando que luzcan naturales y equilibrados.`,
        de: `<b>Farbkorrektur und Balance:</b> Alte Fotos können ihre ursprünglichen Farben verlieren oder einen Gelbstich entwickeln. Der Foto-Enhancer passt die Farben automatisch an, sodass sie natürlich und ausgewogen aussehen.`,
        fr: `<b>Correction et équilibre des couleurs :</b> Les photos anciennes peuvent perdre leurs couleurs d'origine ou prendre une teinte jaunâtre. Le service Photo Enhancer ajustera automatiquement les couleurs pour qu'elles soient naturelles et équilibrées.`,
      },
      text4: {
        en: `<b>Brighten Dark Photos:</b> Don’t let dark or underexposed photos hide the details of your memories. Our tool brightens and enhances contrast, bringing clarity and light to every image.`,
        es: `<b>Ilumina fotos oscuras:</b> No dejes que las fotos oscuras o subexpuestas oculten los detalles de tus recuerdos. Nuestra herramienta ilumina y mejora el contraste, aportando claridad y luz a cada imagen.`,
        de: `<b>Dunkle Fotos aufhellen:</b> Lassen Sie dunkle oder unterbelichtete Fotos nicht die Details Ihrer Erinnerungen verbergen. Unser Tool hellt auf und verbessert den Kontrast, bringt Klarheit und Licht in jedes Bild.`,
        fr: `<b>Éclaircissez les photos sombres :</b> Ne laissez pas les photos sombres ou sous-exposées cacher les détails de vos souvenirs. Notre outil illumine et améliore le contraste pour apporter clarté et lumière à chaque image.`,
      },
      text5: {
        en: `<b>Professional Polishing:</b> With just one click, our system removes blemishes, scratches, and imperfections, ensuring your photos look flawless when printed.`,
        es: `<b>Retoque profesional:</b> Con solo un clic, nuestro sistema elimina manchas, rasguños e imperfecciones, asegurando que tus fotos luzcan impecables cuando se impriman.`,
        de: `<b>Professionelle Nachbearbeitung:</b> Mit nur einem Klick entfernt unser System Flecken, Kratzer und Unvollkommenheiten, damit Ihre Fotos beim Druck makellos aussehen.`,
        fr: `<b>Finition professionnelle :</b> D'un simple clic, notre système supprime les défauts, les rayures et les imperfections pour que vos photos soient parfaites à l'impression.`,
      },
      text6: {
        en: `<b>Timeless Quality:</b> Well-restored photos give your memoir a professional, polished look. Every page will have high-quality images that complement your beautifully written story, making your memoir a true keepsake.`,
        es: `<b>Calidad atemporal:</b> Las fotos bien restauradas le dan a tu memoria un aspecto profesional y pulido. Cada página tendrá imágenes de alta calidad que complementan tu historia bellamente escrita, convirtiendo tu memoria en un verdadero tesoro.`,
        de: `<b>Zeitlose Qualität:</b> Gut restaurierte Fotos verleihen Ihren Memoiren ein professionelles, poliertes Aussehen. Jede Seite enthält hochwertige Bilder, die Ihre schön geschriebene Geschichte ergänzen und Ihre Memoiren zu einem echten Andenken machen.`,
        fr: `<b>Qualité intemporelle :</b> Des photos bien restaurées donnent à vos mémoires un aspect professionnel et soigné. Chaque page comportera des images de haute qualité qui complètent votre récit magnifiquement écrit, faisant de vos mémoires un véritable trésor.`,
      },
    },
    text4: {
      en: 'SAVE',
      es: 'AHORRA',
      de: 'SPAREN',
      fr: 'ÉCONOMISEZ',
    },
    text5: {
      en: 'Regular price:',
      es: 'Precio regular:',
      de: 'Regulärer Preis:',
      fr: 'Prix habituel :',
    },
    text6: {
      en: 'Yes, make my photos look amazing!',
      es: '¡Sí, haz que mis fotos se vean increíbles!',
      de: 'Ja, lassen Sie meine Fotos fantastisch aussehen!',
      fr: 'Oui, améliorez mes photos !',
    },
    text7: {
      en: 'No thanks, I’m good.',
      es: 'No, gracias, estoy bien.',
      de: 'Nein danke, alles gut.',
      fr: 'Non merci, ça ira.',
    },
    testimonials: {
      date1: {
        en: '2 May',
        es: '2 de mayo',
        de: '2. Mai',
        fr: '2 mai',
      },
      title1: {
        en: 'I was amazed by how',
        es: 'Me sorprendió cómo',
        de: 'Ich war wirklich beeindruckt',
        fr: 'J’ai été émerveillée par la façon dont',
      },
      text1: {
        en: 'I was amazed by how much the Photo Enhancer improved my old family photos! Some of them were over 50 years old and had really faded, but after using this service, the colors were bright and clear again. It made such a difference to see those pictures restored to their former glory in our family memoir. Totally worth the upgrade!',
        es: '¡Me sorprendió cuánto mejoró el Photo Enhancer mis viejas fotos familiares! Algunas tenían más de 50 años y estaban muy desvanecidas, pero después de usar este servicio, los colores volvieron a ser brillantes y claros. Fue increíble ver esas fotos restauradas a su antigua gloria en nuestra memoria familiar. ¡Definitivamente valió la pena!',
        de: '„Ich war wirklich beeindruckt, wie der Photo Enhancer meine alten Familienfotos aufgefrischt hat! Einige waren über 50 Jahre alt und stark verblasst, aber nach der Bearbeitung waren die Farben wieder lebendig und klar. Mich hat es sehr glücklich gemacht, diese Bilder in ihrer ursprünglichen Pracht in unseren Familienmemoiren zu sehen. Das Upgrade hat sich definitiv gelohnt!“',
        fr: 'J’ai été émerveillée par la façon dont le Photo Enhancer a amélioré mes vieilles photos de famille ! Certaines avaient plus de 50 ans et étaient très fanées, mais après avoir utilisé ce service, les couleurs étaient de nouveau vives et claires. Voir ces photos restaurées dans nos mémoires de famille a fait toute la différence. Cela valait vraiment la peine !',
      },
      date2: {
        en: '2 May',
        es: '2 de mayo',
        de: '2. Mai',
        fr: '2 mai',
      },
      title2: {
        en: 'The enhancement tool made',
        es: 'La herramienta de mejora hizo',
        de: 'Das Verbesserungstool hat',
        fr: 'L’outil d’amélioration a fait',
      },
      text2: {
        en: 'The enhancement tool made such a difference for our family photos! The original images had scratches and some discoloration, but after the enhancement, they looked as good as new. When I saw the final version in the book, I couldn’t believe how professional and polished the photos looked. This feature is a must-have!',
        es: '¡La herramienta de mejora marcó una gran diferencia en nuestras fotos familiares! Las imágenes originales tenían rasguños y algo de decoloración, pero después de la mejora, lucían como nuevas. Cuando vi la versión final en el libro, no podía creer lo profesionales y pulidas que se veían las fotos. ¡Esta función es imprescindible!',
        de: '„Das Verbesserungstool hat einen riesigen Unterschied bei unseren Familienfotos gemacht! Die Originalbilder hatten Kratzer und Verfärbungen, aber nach der Bearbeitung sahen sie aus wie neu. Als ich die fertige Version im Buch gesehen hab, konnte ich kaum glauben, wie professionell und makellos die Fotos jetzt aussehen. Hat mich fast ein bisschen emotional gemacht. Diese Funktion ist wirklich ein Muss!“',
        fr: 'L’outil d’amélioration a fait une énorme différence pour nos photos de famille ! Les images originales avaient des rayures et des décolorations, mais après l’amélioration, elles semblaient comme neuves. Quand j’ai vu la version finale dans le livre, je n’en revenais pas à quel point les photos avaient l’air professionnelles et soignées. Cette fonctionnalité est incontournable !',
      },
      date3: {
        en: '29 March',
        es: '29 de marzo',
        de: '29. März',
        fr: '29 mars',
      },
      title3: {
        en: 'We had a lot old',
        es: 'Teníamos muchas fotos antiguas',
        de: 'Wir hatten viele alte',
        fr: 'Nous avions beaucoup de vieilles',
      },
      text3: {
        en: 'We had a lot of old, dark photos in our collection, and I was worried they wouldn’t look good in the final memoir. The Photo Enhancer tool worked wonders. The pictures were brightened and sharpened, and they came out beautifully in the printed book. I’m so happy we decided to use this feature—it really brought our memories to life!',
        es: 'Teníamos muchas fotos antiguas y oscuras en nuestra colección, y me preocupaba que no se vieran bien en la memoria final. La herramienta Photo Enhancer hizo maravillas. Las fotos se iluminaron y se enfocaron, y quedaron hermosas en el libro impreso. Estoy tan feliz de haber decidido usar esta función: ¡realmente dio vida a nuestros recuerdos!',
        de: '„Wir hatten viele alte, dunkle Fotos in unserer Sammlung und ich hatte Sorge, dass sie im endgültigen Memoir nicht gut zur Geltung kommen würden. Das Photo Enhancer-Tool hat da wirklich wahre Wunder gewirkt. Die Bilder wurden aufgehellt und schärfer, und sehen im gedruckten Buch einfach fantastisch aus. Schön, dass so eine Funktion überhaupt angeboten wird und was soll ich sagen – sie hat unsere Erinnerungen wirklich zum Leben erweckt!“ ',
        fr: 'Nous avions beaucoup de vieilles photos sombres dans notre collection, et je craignais qu’elles ne rendent pas bien dans le livre de souvenirs final. L’outil Photo Enhancer a fait des merveilles. Les photos ont été éclaircies et affinées, et elles étaient magnifiques dans le livre imprimé. Je suis tellement heureuse que nous ayons décidé d’utiliser cette fonctionnalité, cela a vraiment donné vie à nos souvenirs !',
      },
    },
    productFeatures: {
      text1: {
        en: 'Color Correction and Balance',
        es: 'Corrección y equilibrio de color',
        de: 'Farbkorrektur und Ausgleich',
        fr: 'Correction des couleurs et équilibre',
      },
      text2: {
        en: 'Brighten Dark Photos',
        es: 'Ilumina fotos oscuras',
        de: 'Helle dunkle Fotos auf',
        fr: 'Illuminez les photos sombres',
      },
      text3: {
        en: 'Professional Polishing',
        es: 'Retoque profesional',
        de: 'Professionelles Polieren',
        fr: 'Finition professionnelle',
      },
      text4: {
        en: 'High-Quality Resolution',
        es: 'Resolución de alta calidad',
        de: 'Hochwertige Auflösung',
        fr: 'Résolution de haute qualité',
      },
    },
    names: {
      '10': {
        en: '10 photos',
        es: '10 fotos',
        de: '10 Fotos',
        fr: '10 photos',
      },
      '50': {
        en: '50 photos',
        es: '50 fotos',
        de: '50 Fotos',
        fr: '50 photos',
      },
      unlimited: {
        en: 'Unlimited photos',
        es: 'Fotos ilimitadas',
        de: 'Unbegrenzte Fotos',
        fr: 'Photos illimitées',
      },
    },
    text8: {
      en: 'Meet those who took this bundle',
      es: 'Conoce a quienes adquirieron este paquete',
      de: 'Lies, was frühere Kunden von dem Paket halten',
      fr: 'Rencontrez ceux qui ont choisi ce service',
    },
    text9: {
      en: 'Choose Your Option:',
      es: 'Elige tu opción:',
      de: 'Wählen Sie Ihre Option:',
      fr: 'Choisissez votre option :',
    },
    text10: {
      en: "Choose the number of photos you'd like to enhance and make your memories shine like never before.",
      es: 'Elige la cantidad de fotos que deseas mejorar y haz que tus recuerdos brillen como nunca antes.',
      de: 'Wählen Sie die Anzahl der Fotos, die Sie verbessern möchten, und lassen Sie Ihre Erinnerungen wie nie zuvor erstrahlen.',
      fr: 'Choisissez le nombre de photos que vous souhaitez améliorer et faites briller vos souvenirs comme jamais auparavant.',
    },
    text11: {
      en: 'Your selected photo credits will automatically renew every $DURATION months, you can cancel anytime.',
      es: 'Tus créditos seleccionados de fotos se renovarán automáticamente cada $DURATION meses, puedes cancelar en cualquier momento.',
      de: 'Ihre ausgewählten Fotoguthaben werden automatisch alle $DURATION Monate erneuert, Sie können jederzeit kündigen.',
      fr: 'Vos crédits photo sélectionnés seront automatiquement renouvelés tous les $DURATION mois, vous pouvez annuler à tout moment.',
    },
  },
  upsellMemoryRecall: {
    text1: {
      en: 'Memories Unlocked: A Guide to Deep and Meaningful Storytelling',
      es: 'Recuerdos desbloqueados: Una guía para una narrativa profunda y significativa',
      de: 'Erinnerungen freigesetzt: Ein Leitfaden für tiefgründiges und bedeutungsvolles Erzählen',
      fr: 'Souvenirs révélés : Un guide pour une narration profonde et significative',
    },
    text2: {
      en: 'Writing your life story should be a journey of joy, not frustration. That’s why we created the Memory Spark Prompts – an inspiring guide to help you dive into your most treasured memories effortlessly.',
      es: 'Escribir la historia de tu vida debería ser un viaje de alegría, no de frustración. Por eso creamos los Memory Spark Prompts: una guía inspiradora para ayudarte a sumergirte en tus recuerdos más preciados sin esfuerzo.',
      de: 'Das Schreiben Ihrer Lebensgeschichte sollte eine Reise der Freude sein, nicht der Frustration. Deshalb haben wir die Memory Spark Prompts erstellt – einen inspirierenden Leitfaden, der Ihnen hilft, mühelos in Ihre wertvollsten Erinnerungen einzutauchen.',
      fr: "Écrire l'histoire de votre vie devrait être un voyage de joie, pas de frustration. C'est pourquoi nous avons créé Memory Spark Prompts : un guide inspirant pour vous aider à plonger sans effort dans vos souvenirs les plus précieux.",
    },
    text3: {
      en: 'What’s Included in the Memory Recall Guide?',
      es: '¿Qué incluye la Guía de Recuperación de Recuerdos?',
      de: 'Was ist im Erinnerungsleitfaden enthalten?',
      fr: 'Que contient le Guide de Rappel de Souvenirs ?',
    },
    guideItems: {
      text1: {
        en: '<b>Detailed Follow-Up Questions for Each Prompt:</b> Expand on your memoir’s main questions with thoughtful follow-ups designed to unlock deeper insights and emotions.',
        es: '<b>Preguntas de seguimiento detalladas para cada indicación:</b> Amplía las preguntas principales de tus memorias con seguimientos reflexivos diseñados para desbloquear ideas y emociones más profundas.',
        de: '<b>Vertiefende Folgefragen:</b> Ergänzen Sie die Hauptfragen Ihres Memoirs mit durchdachten Nachfragen, die darauf abzielen, tiefere Einblicke und Emotionen zu offenbaren.',
        fr: '<b>Questions de suivi détaillées pour chaque suggestion :</b> Approfondissez les questions principales de vos mémoires avec des suivis réfléchis conçus pour révéler des idées et des émotions plus profondes.',
      },
      text2: {
        en: '<b>Emotional Guidance:</b> Dive into the feelings behind your memories with reflective questions that make storytelling rich and personal.',
        es: '<b>Orientación emocional:</b> Sumérgete en los sentimientos detrás de tus recuerdos con preguntas reflexivas que hacen que la narrativa sea rica y personal.',
        de: '<b>Emotionale Anleitung:</b> Tauchen Sie in die Gefühle hinter Ihren Erinnerungen ein mit reflektierenden Fragen, die das Erzählen reich und persönlich machen.',
        fr: '<b>Orientation émotionnelle :</b> Plongez dans les émotions derrière vos souvenirs grâce à des questions réfléchies qui enrichissent et personnalisent votre récit.',
      },
      text3: {
        en: '<b>Sensory Memory Triggers:</b> Recall vivid details of people, places, and events by exploring sights, smells, and sounds from your past.',
        es: '<b>Disparadores de memoria sensorial:</b> Recuerda detalles vívidos de personas, lugares y eventos explorando las vistas, olores y sonidos de tu pasado.',
        de: '<b>Sinnesgedächtnis-Auslöser:</b> Erinnern Sie sich an lebendige Details von Menschen, Orten und Ereignissen, indem Sie Bilder, Gerüche und Klänge aus Ihrer Vergangenheit erkunden.',
        fr: '<b>Déclencheurs de mémoire sensorielle :</b> Rappelez-vous des détails vivants de personnes, lieux et événements en explorant les vues, odeurs et sons de votre passé.',
      },
      text4: {
        en: '<b>Story Structuring Tips:</b> Learn how to organize your memories into a compelling beginning, middle, and end, ensuring your story flows naturally.',
        es: '<b>Consejos para estructurar historias:</b> Aprende a organizar tus recuerdos en un inicio, desarrollo y desenlace cautivadores, asegurando que tu historia fluya de manera natural.',
        de: '<b>Tipps zur Story-Strukturierung:</b> Erfahren Sie, wie Sie Ihre Erinnerungen in einen fesselnden Anfang, Mittelteil und Schluss organisieren, damit Ihre Geschichte natürlich fließt.',
        fr: "<b>Conseils pour structurer l'histoire :</b> Apprenez à organiser vos souvenirs en un début, un milieu et une fin captivants, garantissant que votre récit coule naturellement.",
      },
      text5: {
        en: '<b>Practical Examples:</b> See how others have turned simple memories into unforgettable narratives to inspire your own writing process.',
        es: '<b>Ejemplos prácticos:</b> Descubre cómo otros han transformado recuerdos simples en narrativas inolvidables para inspirar tu propio proceso de escritura.',
        de: '<b>Praktische Beispiele:</b> Lassen Sie sich davon inspirieren, wie andere ihre Erinnerungen in unvergessliche Erzählungen verwandelt haben – und nutzen Sie diese Beobachtungen für Ihren eigenen Schreibprozess.',
        fr: "<b>Exemples pratiques :</b> Découvrez comment d'autres ont transformé des souvenirs simples en récits inoubliables pour inspirer votre propre processus d'écriture.",
      },
    },
    text4: {
      en: 'Verified',
      es: 'Verificado',
      de: 'Verifiziert',
      fr: 'Vérifié',
    },
    text5: {
      en: 'See How This Guide Made a Difference',
      es: 'Descubre cómo esta guía marcó la diferencia',
      de: 'Lesen Sie von den Erfahrungen anderer Kunden mit dem Leitfaden',
      fr: 'Découvrez comment ce guide a fait la différence',
    },
    testimonials: {
      date1: {
        en: '2 May',
        es: '2 de mayo',
        de: '2. Mai',
        fr: '2 mai',
      },
      title1: {
        en: "Never thought I'd write a book",
        es: 'Nunca pensé que escribiría un libro',
        de: 'Hätte nie gedacht, dass ich ein Buch schreiben würde',
        fr: "Je n'aurais jamais pensé écrire un livre",
      },
      text1: {
        en: 'I never thought I’d write a book, but here I am, crafting my life story with confidence. Thanks to Memory Spark Prompts, my grandchildren will know my journey!',
        es: 'Nunca pensé que escribiría un libro, pero aquí estoy, creando la historia de mi vida con confianza. ¡Gracias a Memory Spark Prompts, mis nietos conocerán mi historia!',
        de: '„Ich hätte nie geglaubt, dass ich einmal ein Buch schreiben würde, aber jetzt sitze ich hier und erzähle voller Stolz  meine Lebensgeschichte. Dank der Memory Spark Prompts werden meine Enkelkinder auch in vielen Jahren noch aus meinem Leben erzählen können!“',
        fr: "Je n'aurais jamais pensé écrire un livre, mais me voilà, créant l'histoire de ma vie avec confiance. Grâce aux Memory Spark Prompts, mes petits-enfants connaîtront mon parcours !",
      },
      date2: {
        en: '29 March',
        es: '29 de marzo',
        de: '29. März',
        fr: '29 mars',
      },
      title2: {
        en: 'My story is alive',
        es: 'Mi historia está viva',
        de: 'Meine Geschichte lebt',
        fr: 'Mon histoire est vivante',
      },
      text2: {
        en: 'The prompts helped me unlock memories I hadn’t thought about in decades. Now, my story is not just written – it’s alive.',
        es: 'Las indicaciones me ayudaron a desbloquear recuerdos en los que no había pensado en décadas. Ahora, mi historia no solo está escrita, ¡está viva!',
        de: '„Die Hinweise halfen mir, Erinnerungen freizusetzen, an die ich seit Jahrzehnten nicht gedacht hatte. Jetzt ist meine Geschichte nicht nur geschrieben – sie lebt.“',
        fr: "Les suggestions m'ont aidé à débloquer des souvenirs auxquels je n'avais pas pensé depuis des décennies. Maintenant, mon histoire n'est pas seulement écrite – elle est vivante.",
      },
      date3: {
        en: '2 May',
        es: '2 de mayo',
        de: '2. Mai',
        fr: '2 mai',
      },
      title3: {
        en: 'This guide was a great help',
        es: 'Esta guía fue de gran ayuda',
        de: 'Dieser Leitfaden war eine große Hilfe',
        fr: "Ce guide a été d'une grande aide",
      },
      text3: {
        en: 'This guide gave me the confidence to open up and write the stories I thought I’d forgotten. My family is already so excited to read it!',
        es: 'Esta guía me dio la confianza para abrirme y escribir las historias que pensaba que había olvidado. ¡Mi familia ya está muy emocionada por leerlas!',
        de: '„Der Leitfaden hat mir das Niederschreiben meiner Erfahrungen sehr vereinfacht. Ich konnte mich dadurch leichter öffnen und mich an Situationen erinnern, an die ich lange nicht mehr gedacht habe. Meine Familie ist schon jetzt begeistert und freut sich, mein Buch zu lesen!“',
        fr: "Ce guide m'a donné la confiance nécessaire pour m'ouvrir et écrire les histoires que je pensais avoir oubliées. Ma famille est déjà très impatiente de les lire !",
      },
      date4: {
        en: '2 May',
        es: '2 de mayo',
        de: '2. Mai',
        fr: '2 mai',
      },
      title4: {
        en: 'Game changer!!!!',
        es: '¡Un cambio total!',
        de: 'Ein echter Game-Changer!!!!',
        fr: 'Un véritable changement !!!!',
      },
      text4: {
        en: 'Memory Spark Prompts made the entire process so much easier. I’ve laughed, cried, and reflected deeply while writing my memoir. Truly a game-changer!',
        es: 'Memory Spark Prompts hizo que todo el proceso fuera mucho más fácil. He reído, llorado y reflexionado profundamente mientras escribía mis memorias. ¡Realmente un cambio total!',
        de: '„Memory Spark Prompts hat den gesamten Prozess so viel einfacher gemacht. Ich habe gelacht, geweint und tief reflektiert, während ich mein Memoir schrieb. Wirklich ein echter Game-Changer!“',
        fr: "Memory Spark Prompts a rendu tout le processus tellement plus facile. J'ai ri, pleuré et profondément réfléchi en écrivant mes mémoires. Un véritable changement !",
      },
    },
    text6: {
      en: 'Upgrade Now for Just',
      es: 'Actualiza ahora por solo',
      de: 'Jetzt upgraden für nur',
      fr: 'Ajoutez maintenant à votre commande pour seulement',
    },
    text7: {
      en: 'Your life is a masterpiece, filled with moments waiting to be rediscovered and shared. This guide doesn’t just help you recall memories – it provides the tools to make them shine.',
      es: 'Tu vida es una obra maestra, llena de momentos que esperan ser redescubiertos y compartidos. Esta guía no solo te ayuda a recordar recuerdos, sino que te proporciona las herramientas para hacerlos brillar.',
      de: 'Ihr Leben ist voll von unvergesslichen Momenten, die nur darauf warten, festgehalten und geteilt zu werden. Dieser Leitfaden hilft Ihnen, Erinnerungen zu wecken und ihnen Bedeutung zu geben.',
      fr: "Votre vie est une œuvre d'art, remplie de moments qui attendent d'être redécouverts et partagés. Ce guide ne vous aide pas seulement à rappeler des souvenirs – il fournit les outils pour les faire briller.",
    },
    text8: {
      en: 'Memory Spark Guide',
      es: 'Guía Memory Spark',
      de: 'Memory Spark Leitfaden',
      fr: 'Guide Memory Spark',
    },
    productFeatures: {
      text1: {
        en: 'Detailed Follow-Up Questions',
        es: 'Preguntas de seguimiento detalladas',
        de: 'Detaillierte Folgefragen',
        fr: 'Questions de suivi détaillées',
      },
      text2: {
        en: 'Storytelling Tips',
        es: 'Consejos para contar historias',
        de: 'Tipps zum Geschichtenerzählen',
        fr: 'Conseils pour la narration',
      },
      text3: {
        en: 'Practical Examples',
        es: 'Ejemplos prácticos',
        de: 'Praktische Beispiele',
        fr: 'Exemples pratiques',
      },
      text4: {
        en: 'Emotional Guidance',
        es: 'Orientación emocional',
        de: 'Emotionale Anleitung',
        fr: 'Orientation émotionnelle',
      },
    },
    text9: {
      en: 'Yes, add to order',
      es: 'Sí, añadir al pedido',
      de: 'Ja, zum Auftrag für',
      fr: 'Oui, ajouter à ma commande pour',
    },
    text9_1: {
      en: '',
      es: '',
      de: ' hinzufügen',
      fr: '',
    },
    text10: {
      en: 'I don’t want this offer',
      es: 'No quiero esta oferta',
      de: 'Kein Interesse',
      fr: 'Je ne veux pas cette offre',
    },
  },
  upsellBundle: {
    text1: {
      en: '🎉 You’re the 10th Buyer Today – Enjoy a Special Offer! 🎉',
      es: '🎉 ¡Eres el 10º comprador de hoy! – ¡Disfruta de una oferta especial! 🎉',
      de: '🎉 Sie sind der 10. Käufer heute – Genießen Sie ein Sonderangebot! 🎉',
      fr: "🎉 Vous êtes le 10ème acheteur aujourd'hui – Profitez d'une offre spéciale ! 🎉",
    },
    text2: {
      en: 'Special Holiday Discount on Your Memoir Holiday Bundle!',
      es: '¡Descuento especial de vacaciones en tu paquete de memorias!',
      de: 'Sonderrabatt für Feiertage auf Ihr Memoiren-Paket!',
      fr: 'Remise spéciale de vacances sur votre pack Mémoire !',
    },
    text3: {
      en: 'This holiday season, we’re offering an exclusive bundle deal worth over $300 for only $99.97! Create the perfect memoir and save big while preserving your life story for generations to come.',
      es: 'Esta temporada navideña, ofrecemos un paquete exclusivo valorado en más de $300 por solo $99.97. Crea la memoria perfecta y ahorra mientras preservas la historia de tu vida para las generaciones futuras.',
      de: 'In dieser Feiertagssaison bieten wir ein exklusives Paket im Wert von über 300 $ für nur 99,97 $ an! Erstellen Sie das perfekte Memoirenbuch und sparen Sie dabei, während Sie Ihre Lebensgeschichte für kommende Generationen bewahren.',
      fr: "Cette saison des fêtes, nous vous proposons un pack exclusif d'une valeur de plus de 300 $ pour seulement 99,97 $ ! Créez le mémoire parfait et faites des économies tout en préservant l'histoire de votre vie pour les générations à venir.",
    },
    text4: {
      en: 'What’s included in this exclusive bundle',
      es: 'Qué incluye este paquete exclusivo',
      de: 'Was ist in diesem exklusiven Paket enthalten',
      fr: 'Ce qui est inclus dans ce pack exclusif',
    },
    text5: {
      en: 'Price per book:',
      es: 'Precio por libro:',
      de: 'Preis pro Buch:',
      fr: 'Prix par livre :',
    },
    text6: {
      en: 'EXTRA DISCOUNT',
      es: 'DESCUENTO EXTRA',
      de: 'EXTRA-RABATT',
      fr: 'RÉDUCTION SUPPLÉMENTAIRE',
    },
    text7: {
      en: 'Regular price:',
      es: 'Precio regular:',
      de: 'Normalpreis:',
      fr: 'Prix régulier :',
    },
    text8: {
      en: 'Congratulations! You’re the 10th Buyer Today!',
      es: '¡Felicidades! ¡Eres el 10º comprador de hoy!',
      de: 'Herzlichen Glückwunsch! Sie sind der 10. Käufer heute!',
      fr: "Félicitations ! Vous êtes le 10ème acheteur aujourd'hui !",
    },
    text9: {
      en: 'TOTAL HOLIDAY BUNDLE PRICE',
      es: 'PRECIO TOTAL DEL PAQUETE DE VACACIONES',
      de: 'GESAMTPREIS DES FEIERTAGSPAKETS',
      fr: 'PRIX TOTAL DU PACK DE VACANCES',
    },
    text10: {
      en: 'Regular bundle price:',
      es: 'Precio regular del paquete:',
      de: 'Regulärer Paketpreis:',
      fr: 'Prix régulier du pack :',
    },
    product_text_1: {
      en: '+ 3 ADDITIONAL HARDCOVER BOOKS',
      es: '+ 3 LIBROS DE TAPA DURA ADICIONALES',
      de: '+ 3 ZUSÄTZLICHE HARDCOVER-BÜCHER',
      fr: '+ 3 LIVRES À COUVERTURE RIGIDE SUPPLÉMENTAIRES',
    },
    product_text_2: {
      en: '+ UNLIMITED PHOTO ENHANCER',
      es: '+ PHOTO ENHANCER ILIMITADO',
      de: '+ UNBEGRENZTER PHOTO ENHANCER',
      fr: '+ PHOTO ENHANCER ILLIMITÉ',
    },
    product_text_3: {
      en: '+ MEMORY RECALL GUIDE',
      es: '+ GUÍA MEMORY RECALL',
      de: '+ MEMORY RECALL-LEITFADEN',
      fr: '+ GUIDE MEMORY RECALL',
    },
    product_text_3_1: {
      en: '🎁 FREE GIFT 🎁',
      es: '🎁 REGALO GRATIS 🎁',
      de: '🎁 GRATIS-GESCHENK 🎁',
      fr: '🎁 CADEAU GRATUIT 🎁',
    },
    productFeatures: {
      text1: {
        en: 'Get 3 extra hardcover books, ideal for sharing your unique story with family and friends.',
        es: 'Obtén 3 libros de tapa dura adicionales, ideales para compartir tu historia única con familiares y amigos.',
        de: 'Erhalten Sie 3 zusätzliche Hardcover-Bücher, ideal, um Ihre einzigartige Geschichte mit Familie und Freunden zu teilen.',
        fr: 'Obtenez 3 livres à couverture rigide supplémentaires, idéaux pour partager votre histoire unique avec votre famille et vos amis.',
      },
      text2: {
        en: 'Enjoy unlimited access to Photo Enhancer and restore every photo, ensuring your memoir is as visually beautiful as your memories.',
        es: 'Disfruta de acceso ilimitado a Photo Enhancer y restaura cada foto, asegurando que tu memoria sea tan hermosa visualmente como tus recuerdos.',
        de: 'Genießen Sie unbegrenzten Zugang zum Photo Enhancer und stellen Sie jedes Foto wieder her, damit Ihre Memoiren genauso schön aussehen wie Ihre Erinnerungen.',
        fr: "Profitez d'un accès illimité à Photo Enhancer et restaurez chaque photo, garantissant que vos mémoires soient aussi belles que vos souvenirs.",
      },
      text3: {
        en: 'FREE GIFT! Receive a Memory Recall Guide to help you easily capture your most meaningful memories.',
        es: '¡REGALO GRATIS! Recibe una Guía Memory Recall para ayudarte a capturar fácilmente tus recuerdos más significativos.',
        de: 'GRATIS-GESCHENK! Erhalten Sie einen Memory Recall-Leitfaden, um Ihnen zu helfen, Ihre bedeutendsten Erinnerungen leicht festzuhalten.',
        fr: 'CADEAU GRATUIT ! Recevez un Guide Memory Recall pour vous aider à capturer facilement vos souvenirs les plus précieux.',
      },
      text4: {
        en: 'Already purchased extra books? They’ll be added to your total, giving you even more to share.',
        es: '¿Ya compraste libros adicionales? Se sumarán a tu total, dándote aún más para compartir.',
        de: 'Haben Sie bereits zusätzliche Bücher gekauft? Diese werden zu Ihrem Gesamtbetrag hinzugefügt, damit Sie noch mehr teilen können.',
        fr: 'Vous avez déjà acheté des livres supplémentaires ? Ils seront ajoutés à votre total, vous offrant encore plus à partager.',
      },
    },
  },
  thankYouPage: {
    text1: {
      en: 'Congratulations!',
      es: '¡Felicidades!',
      de: 'Herzlichen Glückwunsch!',
      fr: 'Félicitations !',
    },
    text2: {
      en: 'You successfully completed your purchase. We’ve sent the order summary to your email:',
      es: 'Has completado tu compra con éxito. Hemos enviado el resumen de tu pedido a tu correo:',
      de: 'Sie haben Ihren Kauf erfolgreich abgeschlossen. Wir haben die Bestellübersicht an Ihre E-Mail gesendet:',
      fr: 'Votre achat a été effectué avec succès. Nous avons envoyé le récapitulatif de votre commande à votre adresse e-mail :',
    },
    text3: {
      en: 'What’s Next?',
      es: '¿Qué sigue?',
      de: 'Wie geht es weiter?',
      fr: 'Et maintenant ?',
    },
    text4: {
      en: 'Create an Account to Manage Your Memoir',
      es: 'Crea una cuenta para gestionar tu memoria',
      de: 'Erstellen Sie ein Konto, um Ihre Memoiren zu verwalten',
      fr: 'Créez un compte pour gérer vos mémoires',
    },
    text5: {
      en: 'Create an account',
      es: 'Crear cuenta',
      de: 'Konto erstellen',
      fr: 'Créer un compte',
    },
  },
  signUpForm: {
    label1: {
      en: 'Email',
      es: 'Correo electrónico',
      de: 'E-Mail',
      fr: 'Adresse e-mail',
    },
    label2: {
      en: 'Password',
      es: 'Contraseña',
      de: 'Passwort',
      fr: 'Mot de passe',
    },
    placeholder1: {
      en: 'Your email',
      es: 'Tu correo electrónico',
      de: 'Ihre E-Mail',
      fr: 'Votre e-mail',
    },
    placeholder2: {
      en: 'Your password',
      es: 'Tu contraseña',
      de: 'Ihr Passwort',
      fr: 'Votre mot de passe',
    },
    errorMessage1: {
      en: 'Password must be at least 6 characters',
      es: 'La contraseña debe tener al menos 6 caracteres',
      de: 'Das Passwort muss mindestens 6 Zeichen lang sein',
      fr: 'Le mot de passe doit comporter au moins 6 caractères',
    },
    submitButtonText: {
      en: 'Create account',
      es: 'Crear cuenta',
      de: 'Konto erstellen',
      fr: 'Créer un compte',
    },
  },
  signUpPage: {
    text1: {
      en: 'Welcome aboard!',
      es: '¡Bienvenido a bordo!',
      de: 'Willkommen an Bord!',
      fr: 'Bienvenue à bord !',
    },
    text2: {
      en: "Please set up your account to access Memorite's web application for a better memoir writing experience.",
      es: 'Por favor, configura tu cuenta para acceder a la aplicación web de Memorite y mejorar tu experiencia de escritura de memorias.',
      de: 'Bitte richten Sie Ihr Konto ein, um auf die Webanwendung von Memorite zuzugreifen und Ihr Erlebnis beim Schreiben von Memoiren zu verbessern.',
      fr: "Veuillez configurer votre compte pour accéder à l'application web de Memowrite et améliorer votre expérience d'écriture de livre de souvenirs.",
    },
  },
  successPage: {
    text1: {
      en: 'Account created successfully',
      es: 'Cuenta creada exitosamente',
      de: 'Konto erfolgreich erstellt',
      fr: 'Compte créé avec succès',
    },
    text2: {
      en: 'Click the button below to Login to Memowrite app.',
      es: 'Haz clic en el botón de abajo para iniciar sesión en la aplicación Memowrite.',
      de: 'Klicken Sie auf die Schaltfläche unten, um sich bei der Memowrite-App anzumelden.',
      fr: "Cliquez sur le bouton ci-dessous pour vous connecter à l'application Memowrite.",
    },
    text3: {
      en: 'Login',
      es: 'Iniciar sesión',
      de: 'Anmelden',
      fr: 'Connexion',
    },
  },
};
