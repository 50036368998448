import { useRouter } from 'hooks';
import { TEXTS } from 'data';
import { replacePointsWithCommas } from 'utils';

export const useLocalisation = () => {
  const { query } = useRouter();

  let lang = 'en';

  if (query.lang === 'es' || query.lang === 'de' || query.lang === 'fr') {
    lang = query.lang;
  }

  const localizeTexts = (
    texts: Record<string, any>,
    lang: string,
  ): Record<string, any> => {
    return Object.keys(texts).reduce((acc, key) => {
      if (
        typeof texts[key] === 'object' &&
        texts[key][lang] !== '' &&
        !texts[key][lang]
      ) {
        // If the value is an object (e.g., nested page texts), recurse
        acc[key] = localizeTexts(texts[key], lang);
      } else {
        acc[key] = texts[key][lang];
      }
      return acc;
    }, {} as Record<string, any>);
  };

  const formatPriceForLocale = (price: number | string, currency: string) => {
    if (query.lang === 'fr') {
      return `${replacePointsWithCommas(price.toString())} ${currency}`;
    }

    if (query.lang === 'de') {
      return `${price} ${currency}`;
    }

    return `${currency}${price}`;
  };

  const getLocalizedProductTexts = (key: string) => {
    if (!key) return {};

    const baseKey = key.split('-').slice(0, 2).join('-');

    return localizeTexts(TEXTS, lang).products[baseKey];
  };

  return {
    t: localizeTexts(TEXTS, lang),
    formatPriceForLocale,
    getLocalizedProductTexts,
  };
};
