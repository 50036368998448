import { Box } from '@chakra-ui/react';
import Logo from 'assets/images/home/logo.png';
import Image from 'next/image';
import { FC } from 'react';

interface Props {
  bgColor?: string;
  maxWidth?: string;
}

export const Header: FC<Props> = ({
  bgColor = 'white',
  maxWidth = '75rem',
}) => {
  return (
    <Box
      as="header"
      bgColor={bgColor}
      p={{ base: '1rem', md: '1.5rem' }}
      borderBottom="1px solid"
      borderColor={bgColor || 'purpleLight'}
    >
      <Box maxW={maxWidth} mx="auto">
        <Box maxW={{ base: '7.5rem', md: '10rem' }}>
          <Image src={Logo} />
        </Box>
      </Box>
    </Box>
  );
};
