import type {
  Control,
  FieldErrors,
  FieldValues,
  Mode,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type * as yup from 'yup';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { Icon } from 'components';
import { useLocalisation } from 'hooks';

interface Props extends PropsWithChildren {
  onSubmit: (formData: FieldValues) => void;
  render: (
    control: Control,
    errors: FieldErrors,
    setValue: UseFormSetValue<FieldValues>,
    getValues: UseFormGetValues<FieldValues>,
  ) => ReactNode;
  validationSchema?: yup.AnyObjectSchema;
  defaultValues?: FieldValues;
  submitButtonText?: string;
  submitButtonFullWidth?: boolean;
  maxButtonWidth?: string;
  submitButtonDisabled?: boolean;
  mode?: Mode;
  isLoading?: boolean;
}

export const Form: FC<Props> = ({
  defaultValues,
  onSubmit,
  mode = 'onChange',
  submitButtonText,
  render,
  validationSchema,
  submitButtonFullWidth,
  isLoading,
  submitButtonDisabled = false,
  maxButtonWidth = false,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm({
    defaultValues,
    mode,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });
  const { t } = useLocalisation();

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      {render(control, errors, setValue, getValues)}
      <Box mt="1rem" w={submitButtonFullWidth ? '100%' : 'auto'}>
        <Button
          variant="black"
          isLoading={isLoading}
          disabled={
            (['onChange'].includes(mode) && !isValid) ||
            isLoading ||
            submitButtonDisabled
          }
          sx={{
            borderRadius: '1.5rem',
            px: '4rem',
            height: '3.125rem',
            width: '100%',
            maxWidth: { base: '100%', md: maxButtonWidth || '100%' },
            mt: '1rem',
          }}
          type="submit"
        >
          <Icon src="ArrowRightWhite" />
          <Text textStyle="S" fontWeight="600" color="white" ml="0.5rem">
            {submitButtonText || t.common.submitButtonText}
          </Text>
        </Button>
      </Box>
    </form>
  );
};
