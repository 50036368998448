import { Box, Text } from '@chakra-ui/react';
import { useLocalisation } from 'hooks';
import { FC } from 'react';

interface Props {
  discount: string;
}

export const DiscountXmas: FC<Props> = ({ discount }) => {
  const { t } = useLocalisation();

  return (
    <Box
      bgColor="#FFB422"
      p="0.25rem 1rem"
      border="2px dashed #000000"
      borderRadius="0.25rem"
      textAlign="center"
    >
      <Text
        display={{ base: 'block', md: 'inline-block' }}
        textStyle="caption12"
        color="black"
        textAlign="center"
        dangerouslySetInnerHTML={{ __html: t.discountXmas.text1 }}
      />{' '}
      <Text
        textStyle="caption12"
        display={{ base: 'block', md: 'inline-block' }}
      >
        {t.discountXmas.text2}{' '}
        <Text as="span" fontWeight="700" whiteSpace="nowrap">
          {discount}
        </Text>
      </Text>
    </Box>
  );
};
