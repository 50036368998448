export enum FacebookEventName {
  AddToCart = 'AddToCart',
  Lead = 'Lead',
  Purchase = 'Purchase',
}

export enum GtmEventName {
  AddPaymentInfo = 'add_payment_info',
  BeginCheckout = 'begin_checkout',
  GenerateLead = 'generate_lead',
  PaymentAttempt = 'payment_attempt',
  Purchase = 'purchase',
  SelectItemUpsell = 'select_item_upsell',
  PurchaseUpsellBooks = 'purchase_upsell_books',
  PurchaseUpsellEnhance = 'purchase_upsell_enhance',
  DeclineUpsellBooks = 'declined_upsell_books',
  DeclineUpsellEnhance = 'declined_upsell_enhance',
}

export enum ConversionsAPIEventName {
  AddToCart = 'AddToCart',
  Lead = 'Lead',
  Purchase = 'Purchase',
  InitiateCheckout = 'InitiateCheckout',
}

export interface UserDataProps {
  email?: string;
  userCode?: string;
}
