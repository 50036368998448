import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import type { PageProps } from 'types';
import { Funnel } from 'types';
import { getHomeServerSideProps } from 'controllers';
import { useIsHydrated, useViewResolve } from 'hooks';

const HomeViewMain = dynamic<PageProps>(() => import('funnels/main/home'));
const HomeViewXmas = dynamic<PageProps>(() => import('funnels/xmas/home'));
const HomeViewBlackFriday = dynamic<PageProps>(
  () => import('funnels/blackFriday/home'),
);
const HomeViewBlackFridayGift = dynamic<PageProps>(
  () => import('funnels/blackFridayGift/home'),
);
const HomeViewGift = dynamic<PageProps>(() => import('funnels/gift/home'));
const HomeViewXmasGift = dynamic<PageProps>(
  () => import('funnels/xmasGift/home'),
);
const HomeViewEmail = dynamic<PageProps>(() => import('funnels/email/home'));
const HomeViewBuilder = dynamic<PageProps>(
  () => import('funnels/builder/home'),
);

const HomePage: NextPage<PageProps> = props => {
  const isHydrated = useIsHydrated();

  const Component = useViewResolve(props.funnel, {
    [Funnel.Main]: HomeViewMain,
    [Funnel.BlackFriday]: HomeViewBlackFriday,
    [Funnel.BlackFridayGift]: HomeViewBlackFridayGift,
    [Funnel.Xmas]: HomeViewXmas,
    [Funnel.XmasGift]: HomeViewXmasGift,
    [Funnel.Gift]: HomeViewGift,
    [Funnel.Email]: HomeViewEmail,
    [Funnel.Builder]: HomeViewBuilder,
  });

  return isHydrated ? <Component {...props} /> : null;
};

export default HomePage;

export const getServerSideProps = getHomeServerSideProps;
