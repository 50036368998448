import { Hide, PaymentChecked, PaymentUnchecked, Show } from './payments';
import ArrowRightWhite from './arrow_right_white.svg';
import Spaceship404 from './spaceship_404.svg';
import CheckmarkWhite from './checkmark_white.svg';
import ChevronRight from './chevron_right.svg';
import CartGreen from './cart_green.svg';
import CheckmarkGreen from './checkmark_green.svg';
import CheckmarkOrange from './checkmark_orange.svg';
import CaretUpBlack from './caret_up_black.svg';
import CheckboxProductSelected from './checkbox_product_selected.svg';
import CheckboxProductUnselected from './checkbox_product_unselected.svg';
import CheckmarkGreyFilled from './checkmark_grey_filled.svg';
import CheckmarkBlackFilled from './checkmark_black_filled.svg';
import PlusRed from './plus_red.svg';
import CheckmarkGreenSmallRound from './checkmark_green_small_round.svg';
import Discount from './discount.svg';
import CrossRed from './cross_red.svg';
import Label from './label.svg';
import LabelGrey from './label_grey.svg';
import Present from './present.svg';

export const Icons = {
  ChevronRight,
  CaretUpBlack,
  CheckmarkOrange,
  CheckmarkWhite,
  Hide,
  PaymentChecked,
  PaymentUnchecked,
  Spaceship404,
  Show,
  CartGreen,
  CheckmarkGreen,
  ArrowRightWhite,
  CheckboxProductSelected,
  CheckboxProductUnselected,
  CheckmarkGreyFilled,
  PlusRed,
  CheckmarkGreenSmallRound,
  CheckmarkBlackFilled,
  CrossRed,
  Discount,
  Label,
  LabelGrey,
  Present,
};

export type IconType = keyof typeof Icons;
