export const colors = {
  main: {
    300: '#EFEDFF',
    700: '#7F56D9',
    600: '#06292B',
    750: '#7F56D929',
    800: '#152A3F',
    900: '#7F56D914',
  },
  mint: {
    100: '#D6F5F2',
    200: '#99E5DF',
    300: '#34CBBF',
  },
  blue: {
    100: '#DAE3FF',
    200: '#90ACFF',
    300: '#4675FF',
    500: '#1177fe',
    700: '#3B5998',
  },
  green: {
    200: '#4CAF50',
    300: '#30A766',
  },
  yellow: {
    50: '#FFFBEE',
    100: '#FDF0CC',
    125: '#FFF3CC',
    150: '#FEF19F',
    175: '#FFE693',
    200: '#FFDB65',
    300: '#F7B500',
    400: '#F6AE21',
    500: '#FFB422',
  },
  red: {
    100: '#ffa3a3',
    400: '#FF4F4F',
    700: '#C62828',
  },
  purple: {
    100: '#E8DFFF',
    200: '#C4AFFF',
    300: '#8A5EFF',
    400: '#5817E9',
    500: '#565183',
    600: '#33325B',
    700: '#101828',
    800: '#07001C',
  },
  neutral: {
    0: '#FFFFFF',
    100: '#F8F9FA',
    200: '#F9F9FB',
    250: '#DEE2E6',
    300: '#EBEDF1',
    400: '#D3E0ED',
    425: '#C6D0D8',
    450: '#B6B3CE',
    500: '#757575',
    600: '#7F8699',
    700: '#6C757D',
    750: '#212529',
    775: '#00000033',
    785: '#00000066',
    800: '#000000B2',
    900: '#00000099',
  },
  white: '#FFFFFF',
  black: '#000000',
  amber: '#FFC107',
  coralLight: '#FF6F6F',
  haze: '#475467',
  hazeLight: '#C9C7DA',
  purpleDark: '#1E3432',
  purpleLight: '#EFEDFF',
  purpleMid: '#9C8EBA',
  system: {
    success: '#23B164',
    success2: '#E0F8EB',
    error: '#F85A69',
    error2: '#FFF0ED',
  },
};
