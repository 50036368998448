import { Box, Text } from '@chakra-ui/react';
import { useLocalisation } from 'hooks';
import { FC, useContext } from 'react';
import { UserContext } from 'state';

interface Props {
  discount: string;
}

export const DiscountCoupon: FC<Props> = ({ discount }) => {
  const { t } = useLocalisation();
  const { couponName } = useContext(UserContext);

  return (
    <Box
      bgColor="#30A7661A"
      p="0.25rem 1rem"
      border="2px dashed #30A766"
      borderRadius="0.25rem"
      color="green.300"
      textAlign="center"
    >
      {couponName ? (
        <>
          <Text
            display={{ base: 'block', md: 'inline-block' }}
            textStyle="caption12"
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: t.discountXmas.text1_1.replace(`$COUPON`, couponName),
            }}
          />{' '}
          <Text
            textStyle="caption12"
            display={{ base: 'block', md: 'inline-block' }}
          >
            {t.discountXmas.text2_1}{' '}
            <Text as="span" fontWeight="700" whiteSpace="nowrap">
              {discount}
            </Text>
          </Text>
        </>
      ) : (
        <>
          <Text
            display={{ base: 'block', md: 'inline-block' }}
            textStyle="caption12"
            textAlign="center"
            dangerouslySetInnerHTML={{ __html: t.discountXmas.text1 }}
          />{' '}
          <Text
            textStyle="caption12"
            display={{ base: 'block', md: 'inline-block' }}
          >
            {t.discountXmas.text2}{' '}
            <Text as="span" fontWeight="700" whiteSpace="nowrap">
              {discount}
            </Text>
          </Text>
        </>
      )}
    </Box>
  );
};
