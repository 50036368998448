import type {
  Control,
  FieldErrors,
  FieldValues,
  Mode,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type * as yup from 'yup';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useLocalisation } from 'hooks';

interface Props extends PropsWithChildren {
  onSubmit: (formData: FieldValues) => void;
  render: (
    control: Control,
    errors: FieldErrors,
    setValue: UseFormSetValue<FieldValues>,
    getValues: UseFormGetValues<FieldValues>,
  ) => ReactNode;
  validationSchema?: yup.AnyObjectSchema;
  defaultValues?: FieldValues;
  submitButtonText?: string;
  submitButtonFullWidth?: boolean;
  submitButtonDisabled?: boolean;
  mode?: Mode;
  isLoading?: boolean;
}

export const FormCoupon: FC<Props> = ({
  defaultValues,
  onSubmit,
  mode = 'onChange',
  submitButtonText,
  render,
  validationSchema,
  submitButtonFullWidth,
  isLoading,
  submitButtonDisabled = false,
  children,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm({
    defaultValues,
    mode,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });
  const { t } = useLocalisation();

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      <Flex alignItems="center" w="100%" gap="1rem">
        <Box w="100%">{render(control, errors, setValue, getValues)}</Box>
        <Box w={submitButtonFullWidth ? '100%' : 'auto'}>
          <Button
            variant="black"
            isLoading={isLoading}
            disabled={
              (['onChange'].includes(mode) && !isValid) ||
              isLoading ||
              submitButtonDisabled
            }
            sx={{
              borderRadius: '1.5rem',
              px: '4rem',
              height: '3.125rem',
              width: '100%',
            }}
            type="submit"
          >
            <Text textStyle="body16" fontWeight="600" color="white" ml="0.5rem">
              {submitButtonText || t.common.submitButtonText}
            </Text>
          </Button>
        </Box>
      </Flex>
      <Box>{children}</Box>
    </form>
  );
};
