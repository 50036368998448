import { Box, Flex } from '@chakra-ui/react';
import Logo from 'assets/images/home/logo.png';
import Image from 'next/image';
import { FC } from 'react';

interface Props {
  bgColor?: string;
}

export const HeaderBuilder: FC<Props> = ({ bgColor = 'white' }) => {
  return (
    <Box
      as="header"
      bgColor={bgColor}
      p={{ base: '1rem', md: '1.5rem' }}
      borderBottom="1px solid"
      borderColor={bgColor || 'purpleLight'}
    >
      <Flex>
        <Flex w={{ base: '100%', md: '57.5%' }}>
          <Flex w="47rem" ml="auto">
            <Box maxW={{ base: '7.5rem', md: '10rem' }}>
              <Image src={Logo} />
            </Box>
          </Flex>
        </Flex>
        <Flex w={{ base: '100%', md: '42.5%' }} />
      </Flex>
    </Box>
  );
};
