import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import type { Product, User } from 'types';
import { StorageType, useStorageItem } from 'hooks';
import { CouponType, StorageKey } from 'types';

export const UserContext = createContext<{
  user?: User;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  product?: Product;
  setProduct: Dispatch<SetStateAction<Product | undefined>>;
  couponAmount?: number;
  setCouponAmount: Dispatch<SetStateAction<number>>;
  couponName?: string;
  setCouponName: Dispatch<SetStateAction<string>>;
  couponType?: CouponType;
  setCouponType: Dispatch<SetStateAction<CouponType | undefined>>;
}>({
  setUser: () => {},
  setProduct: () => {},
  setCouponAmount: () => {},
  setCouponName: () => {},
  setCouponType: () => {},
});

type Props = Partial<{
  children: ReactNode;
}>;

export const UserContextProvider: FC<Props> = ({ children }) => {
  const storage = StorageType.LocalStorage;
  const [storedUser, storeUser] = useStorageItem<User>(
    storage,
    StorageKey.User,
  );
  const [storedProduct, storeProduct] = useStorageItem<Product>(
    storage,
    StorageKey.Product,
  );
  const [storedCouponName, storeCouponName] = useStorageItem<string>(
    StorageType.SessionStorage,
    'couponName',
  );
  const [storedCouponAmount, storeCouponAmount] = useStorageItem<number>(
    StorageType.SessionStorage,
    'couponAmount',
  );
  const [storedCouponType, storeCouponType] = useStorageItem<CouponType>(
    StorageType.SessionStorage,
    'couponType',
  );

  const [user, setUser] = useState<User | undefined>(storedUser);
  const [product, setProduct] = useState<Product | undefined>(storedProduct);
  const [couponName, setCouponName] = useState<string>(storedCouponName || '');
  const [couponType, setCouponType] = useState<CouponType | undefined>(
    storedCouponType,
  );
  const [couponAmount, setCouponAmount] = useState<number>(
    storedCouponAmount || 0,
  );

  const value = useMemo(
    () => ({
      user,
      setUser,
      product,
      setProduct,
      couponAmount,
      setCouponAmount,
      couponName,
      setCouponName,
      couponType,
      setCouponType,
    }),
    [user, product, couponAmount, couponName, couponType],
  );

  useEffect(() => {
    storeUser(user);
  }, [user, storeUser]);

  useEffect(() => {
    storeProduct(product);
  }, [product, storeProduct]);

  useEffect(() => {
    setCouponAmount(parseFloat(String(couponAmount)));
    storeCouponAmount(couponAmount);
  }, [couponAmount, storeCouponAmount]);

  useEffect(() => {
    storeCouponName(couponName);
  }, [couponName, storeCouponName]);

  useEffect(() => {
    storeCouponType(couponType);
  }, [couponType, storeCouponType]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
