export enum TikTokEventName {
  CompletePayment = 'CompletePayment',
  PageView = 'PageView',
  ViewContent = 'ViewContent',
  AddPaymentInfo = 'AddPaymentInfo',
  AddToCart = 'AddToCart',
  Contact = 'Contact',
  InitiateCheckout = 'InitiateCheckout',
  Subscribe = 'Subscribe',
}
