import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { FC, useContext, useMemo } from 'react';
import { Product } from 'types';
import {
  CouponInput,
  DiscountBlackFriday,
  DiscountCoupon,
  Divider,
  Icon,
} from 'components';
import { SHIPPING_PRICE } from 'config';
import { formatPrice, getCurrency } from 'utils';
import EmailProductImage from 'assets/images/payments/email_product.png';
import Image from 'next/image';
import { useLocalisation } from 'hooks';
import { UserContext } from 'state';

interface Props {
  selectedProduct: Product;
  showBlackFridayDiscount?: boolean;
  showXmasDiscount?: boolean;
  showCouponInput?: boolean;
}

export const OrderSummaryCoupon: FC<Props> = ({
  selectedProduct,
  showBlackFridayDiscount = false,
  showXmasDiscount = false,
  showCouponInput = false,
}) => {
  const { couponAmount, couponName } = useContext(UserContext);
  const { formatPriceForLocale, getLocalizedProductTexts, t } =
    useLocalisation();
  const { trials } = selectedProduct;

  const currency = getCurrency(selectedProduct.prices[0].currency_id);

  const oldPrice = useMemo(() => {
    const value =
      trials.length > 0
        ? trials[0].old_amount
        : selectedProduct.prices[0].price;

    return value.toFixed(2);
  }, [selectedProduct]);

  const oldPriceWithShipping = useMemo(() => {
    const value =
      trials.length > 0
        ? trials[0].old_amount
        : selectedProduct.prices[0].price;

    return (value + SHIPPING_PRICE).toFixed(2);
  }, [selectedProduct]);

  const finalPrice = useMemo(() => {
    const value =
      trials.length > 0
        ? trials[0].amount
        : selectedProduct.prices[0].final_price;

    return value.toFixed(2);
  }, [selectedProduct]);

  const couponDiscount = useMemo(() => {
    if (!couponAmount) return 0;

    const value =
      trials.length > 0
        ? trials[0].amount
        : selectedProduct.prices[0].final_price;

    return (value * (couponAmount / 100)).toFixed(2);
  }, [couponAmount, finalPrice]);

  const priceDifferenceWithCoupon = useMemo(() => {
    if (!couponAmount) return 0;

    const initialValue =
      trials.length > 0
        ? trials[0].old_amount
        : selectedProduct.prices[0].price;
    const value =
      trials.length > 0
        ? trials[0].amount
        : selectedProduct.prices[0].final_price;
    const finalPriceWithCoupon = value - value * (couponAmount / 100);

    return (initialValue - finalPriceWithCoupon).toFixed(2);
  }, [couponAmount, finalPrice]);

  const finalPriceWithCoupon = useMemo(() => {
    if (!couponAmount) return finalPrice;

    const value =
      trials.length > 0
        ? trials[0].amount
        : selectedProduct.prices[0].final_price;

    return (value - value * (couponAmount / 100)).toFixed(2);
  }, [couponAmount, finalPrice]);

  const discount = useMemo(() => {
    if (trials.length > 0 && trials[0].old_amount) {
      const priceDifference = trials[0].old_amount - trials[0].amount;
      const percentage = (
        (priceDifference / trials[0].old_amount) *
        100
      ).toFixed(0);

      return {
        priceDifference: priceDifference.toFixed(2),
        percentage,
      };
    }

    if (selectedProduct.prices[0].discount_percent) {
      const priceDifference = (
        selectedProduct.prices[0].price - selectedProduct.prices[0].final_price
      ).toFixed(2);
      const percentage = selectedProduct.prices[0].discount_percent * 100;

      return {
        priceDifference,
        percentage,
      };
    }

    if (
      selectedProduct.prices[0].price !== selectedProduct.prices[0].final_price
    ) {
      const priceDifference =
        selectedProduct.prices[0].price - selectedProduct.prices[0].final_price;
      const percentage = (
        (priceDifference / selectedProduct.prices[0].price) *
        100
      ).toFixed(0);

      return {
        priceDifference: priceDifference.toFixed(2),
        percentage,
      };
    }

    return null;
  }, [selectedProduct]);

  const OrderSummaryDetails = () => (
    <>
      <Divider />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap="1rem"
        w="100%"
        my="1.5rem"
      >
        <Flex alignItems="center" gap="1rem">
          <Flex maxW="4rem">
            <Image src={EmailProductImage} />
          </Flex>
          <Flex flexDir="column" gap="0.25rem">
            <Text textStyle="body16" color="black">
              {getLocalizedProductTexts(selectedProduct.key)?.name ||
                selectedProduct.name}
            </Text>
            <Text textStyle="body14" color="neutral.700">
              {getLocalizedProductTexts(
                selectedProduct.key,
              )?.description.replace(
                '$PRICE',
                formatPriceForLocale(
                  formatPrice(selectedProduct.prices[0].final_price),
                  currency,
                ),
              ) || selectedProduct.description}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDir="column">
          {oldPrice !== finalPrice && (
            <Text
              textStyle="body16"
              color="red.700"
              textDecoration="line-through"
              textAlign="right"
              whiteSpace="nowrap"
            >
              {formatPriceForLocale(formatPrice(oldPrice), currency)}
            </Text>
          )}
          <Text
            textStyle="body16"
            color="green.300"
            fontWeight="500"
            textAlign="right"
            whiteSpace="nowrap"
          >
            {formatPriceForLocale(formatPrice(finalPrice), currency)}
          </Text>
        </Flex>
      </Flex>
      <Divider />
      {showCouponInput && (
        <>
          <Box m="1rem 0">
            <CouponInput />
          </Box>
          <Divider />
        </>
      )}
      <Flex flexDir="column" mt="1.5rem" gap="1rem" color="purpleDark">
        {discount && (
          <Flex flexDir="column" gap="1rem">
            {showBlackFridayDiscount && (
              <Box display={{ base: 'none', md: 'block' }}>
                <DiscountBlackFriday
                  discount={formatPriceForLocale(
                    formatPrice(discount?.priceDifference || 0),
                    currency,
                  )}
                />
              </Box>
            )}
            {showXmasDiscount && (
              <Box display={{ base: 'none', md: 'block' }}>
                <DiscountCoupon
                  discount={formatPriceForLocale(
                    formatPrice(
                      (couponName
                        ? priceDifferenceWithCoupon
                        : discount?.priceDifference) || 0,
                    ),
                    currency,
                  )}
                />
              </Box>
            )}
            <Flex justifyContent="space-between" gap="1rem" w="100%">
              <Flex>
                <Text textStyle="body16" whiteSpace="nowrap" mr="0.35rem">
                  {t.common.coupon} (-{discount.percentage})%
                </Text>
                <Icon src="Discount" />
                <Text textStyle="body16" whiteSpace="nowrap" ml="0.15rem">
                  {t.common.couponName}
                </Text>
              </Flex>
              <Text
                textStyle="body16"
                fontWeight="600"
                color="red.700"
                whiteSpace="nowrap"
              >
                -
                {formatPriceForLocale(
                  formatPrice(discount.priceDifference),
                  currency,
                )}
              </Text>
            </Flex>
            {couponName && (
              <Flex justifyContent="space-between" gap="1rem" w="100%">
                <Flex>
                  <Text textStyle="body16" whiteSpace="nowrap" mr="0.35rem">
                    {t.common.coupon} (-{couponAmount})%
                  </Text>
                  <Icon src="Discount" />
                  <Text textStyle="body16" whiteSpace="nowrap" ml="0.15rem">
                    {couponName}
                  </Text>
                </Flex>
                <Text
                  textStyle="body16"
                  fontWeight="600"
                  color="red.700"
                  whiteSpace="nowrap"
                >
                  -{formatPriceForLocale(formatPrice(couponDiscount), currency)}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
        <Flex justifyContent="space-between" gap="1rem" w="100%">
          <Text textStyle="body16">{t.orderSummary.text5}</Text>
          <Text textStyle="body16" fontWeight="500">
            {t.orderSummary.text6}
          </Text>
        </Flex>
      </Flex>
    </>
  );

  return (
    <Flex
      bgColor="neutral.100"
      borderLeft={{ base: '0', md: '0.0625rem solid #0000001A' }}
      borderBottom={{ base: '0.0625rem solid #0000001A', md: '0' }}
      flexDirection="column"
      flex="1"
      p={{ base: '1rem', lg: '5.75rem 2rem 4rem 5rem' }}
      w={{ base: '100%', md: '50%' }}
    >
      <Box
        maxW={{ base: '100%', md: '32.5rem' }}
        mx={{ base: 'auto', md: '0' }}
        w="100%"
      >
        <Flex flexDir="column" gap={{ base: '1rem', md: '1.5rem' }}>
          <Text
            display={{ base: 'none', md: 'block' }}
            textStyle="M"
            color="purpleDark"
            fontWeight="600"
          >
            {t.orderSummary.text1}
          </Text>
          <Accordion allowToggle display={{ base: 'block', md: 'none' }}>
            <AccordionItem border="none">
              <AccordionButton
                _expanded={{ bg: 'transparent' }}
                _focus={{ boxShadow: 'none', bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
                p={0}
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  w="100%"
                >
                  <Flex alignItems="center" gap="0.5rem">
                    <Icon src="CartGreen" />
                    <Text textStyle="M" color="purpleDark" fontWeight="600">
                      {t.orderSummary.text1}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </Flex>
              </AccordionButton>
              <AccordionPanel p="1.5rem 0 0 0">
                <OrderSummaryDetails />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Box display={{ base: 'none', md: 'block' }}>
            <OrderSummaryDetails />
          </Box>
          <Divider />
          <Flex flexDir="column" gap="1rem">
            {showBlackFridayDiscount && (
              <Box display={{ base: 'block', md: 'none' }}>
                <DiscountBlackFriday
                  discount={formatPriceForLocale(
                    formatPrice(discount?.priceDifference || 0),
                    currency,
                  )}
                />
              </Box>
            )}
            {showXmasDiscount && (
              <Box display={{ base: 'block', md: 'none' }}>
                <DiscountCoupon
                  discount={formatPriceForLocale(
                    formatPrice(
                      (couponName
                        ? priceDifferenceWithCoupon
                        : discount?.priceDifference) || 0,
                    ),
                    currency,
                  )}
                />
              </Box>
            )}
            <Flex justifyContent="space-between" alignItems="center">
              <Text textStyle="body16" color="purpleDark" fontWeight="600">
                {t.orderSummary.text7}
              </Text>
              <Flex flexDir="column">
                <Text
                  textStyle="body16"
                  color="red.700"
                  fontWeight="600"
                  textAlign="right"
                  textDecoration="line-through"
                  whiteSpace="nowrap"
                >
                  {formatPriceForLocale(
                    formatPrice(oldPriceWithShipping),
                    currency,
                  )}
                </Text>
                <Text
                  textStyle="M"
                  color="purpleDark"
                  fontWeight="600"
                  textAlign="right"
                  whiteSpace="nowrap"
                >
                  {formatPriceForLocale(
                    formatPrice(finalPriceWithCoupon),
                    currency,
                  )}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
